/* eslint-disable no-console */
import queryString from 'query-string';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import { connectRoutes } from 'redux-first-router';
import { createLogger } from 'redux-logger';
import promiseMiddleware from 'redux-promise';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import { ReduxStoreOptions } from '~/libs/reduxBinder';
import { SiteState } from '~/state/types';

const sagaMiddleware = createSagaMiddleware();

export interface StoreRequestPath {
  pathname: string;
}

export type ConfigureStoreOptions = ReduxStoreOptions & {
  requestPath: StoreRequestPath;
  initialState: SiteState;
  reduceInitialState: (initialState: SiteState) => SiteState;
  routesMap: any;
  rootReducers: any;
  rootSaga?: any;
  hotReloadPath?: string;
};

export default function initializeStore({
  initialState,
  requestPath,
  reduceInitialState,
  routesMap,
  rootReducers,
  rootSaga,
  hotReloadPath,
}: ConfigureStoreOptions): Store {
  const preloadedState = reduceInitialState(initialState);

  const router = connectRoutes(routesMap, {
    querySerializer: queryString,
    initialEntries: [requestPath],
    initialDispatch: false,
    scrollTop: true,
  });

  const middlewares = [thunk, promiseMiddleware, router.middleware];
  const allReducers = combineReducers<SiteState>({
    ...rootReducers,
    location: router.reducer,
  });

  // Server-side rendering uses an abbreviated store setup that avoids async or dev integrations
  if (__SSR__) {
    router.initialDispatch!();
    return createStore(
      allReducers,
      preloadedState,
      compose(router.enhancer, applyMiddleware(...middlewares))
    );
  }

  // Only add middleware if this is a client bundle
  if (rootSaga) {
    middlewares.push(sagaMiddleware);
  }

  // Only add the logger middleware if the browser doesn't have the (more useful) Redux devtools extension
  if (__DEV__ && !window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    console.log('Enabling verbose logging of redux events in the console.');
    console.log('We recommend using http://extension.remotedev.io instead.');
    middlewares.push(createLogger());
  }

  // Enable redux tracing in dev
  const composeOpts = __DEV__
    ? {
        trace: true,
        traceLimit: 20,
      }
    : {};

  const composeEnhancers =
    (!__SSR__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.(composeOpts)) ||
    compose;

  const enhancer = composeEnhancers(
    router.enhancer,
    applyMiddleware(...middlewares)
  );

  const store = createStore(allReducers, preloadedState, enhancer);

  if (!__SSR__ && __DEV__) {
    if (hotReloadPath) {
      // Enable Webpack hot module replacement for reducers
      module.hot?.accept(hotReloadPath, () => {
        store.replaceReducer(allReducers);
      });
    }
    window.store = store;
  }

  if (rootSaga) {
    sagaMiddleware.run(rootSaga);
  }

  router.initialDispatch!();

  return store;
}
