import { Anchor, Box, Text } from '@codecademy/gamut';
import styled from '@emotion/styled';
import React from 'react';

import { userAttributes } from '~/libs/userAttributes';
import errorStrings from '~/localized/errors/500.json';

const StyledCode = styled.code`
  display: block;
  font-size: 0.85rem;
`;

export const Error500Contents: React.FC<
  React.PropsWithChildren<unknown>
> = () => (
  <Box maxWidth="40rem" my={96} mx="auto" as="main">
    <Text as="h1" fontSize={44}>
      {errorStrings.title}
    </Text>
    <Text as="p">{errorStrings.description}</Text>
    <Box as="ul" mt={16}>
      <li>{errorStrings.refresh}</li>
      <li>{errorStrings.clear_cache}</li>
    </Box>
    <Text as="p">
      {errorStrings.cta.description}{' '}
      <Anchor href="https://help.codecademy.com/hc/en-us">
        {errorStrings.cta.text}
      </Anchor>
      !
    </Text>

    <Box as="details" fontSize={16} textColor="text-disabled">
      <Box as="summary" mt={48}>
        Support information
      </Box>
      {typeof navigator !== 'undefined' && (
        <StyledCode>Browser: {navigator.userAgent}</StyledCode>
      )}
      <StyledCode>Timestamp: {new Date().getTime()}</StyledCode>
      <StyledCode>User ID: {userAttributes.id()}</StyledCode>
    </Box>
  </Box>
);
