import { Konamimojisplosion } from '@codecademy/konamimojisplosion';
import React from 'react';

import { useTrackingIntegration } from '~/components/useTrackingIntegration';
import { trackUserClick } from '~/libs/eventTracking';
import { withEmotion } from '~/libs/withEmotion';

import { Error500Contents } from './Error500Contents';
import { withErrorBoundary } from './errors/withErrorBoundary';

export function createRootComponent<Props>(
  Component: React.ComponentType<React.PropsWithChildren<Props>>,
  emotionOptions: Parameters<typeof withEmotion>[1]
) {
  function RootComponent(props: React.PropsWithChildren<Props>) {
    useTrackingIntegration();
    return (
      <>
        <Component {...props} />
        <Konamimojisplosion
          onActivate={() =>
            trackUserClick({
              context: 'easter_egg',
              target: 'konami_code',
            })
          }
        />
      </>
    );
  }

  return withEmotion(
    withErrorBoundary(RootComponent, <Error500Contents />),
    emotionOptions
  );
}
