import { createAction } from 'redux-actions';

import { containerProgressSummaryRequest } from '~/libs/progressSummaryRequest';
import { SiteThunkAction } from '~/state/types';
import {
  ContainerProgress,
  ProgressSummaryContainerType,
} from '~/typings/entities';

import { selectContainerProgress } from './selectors';
import { progressResetRequestedPayload } from './types';

interface ProgressLoadedPayload {
  type: ProgressSummaryContainerType;
  id: string;
  progress: ContainerProgress;
}

export const progressLoaded = createAction<ProgressLoadedPayload>(
  'containerProgresses/LOADED'
);

export const progressResetRequested = createAction<progressResetRequestedPayload>(
  `containerProgresses/RESET_REQUESTED`
);

export const containerProgressRequested = (
  type: ProgressSummaryContainerType,
  id: string,
  userId?: string
): SiteThunkAction => {
  return async (dispatch, getState) => {
    const currentProgress = selectContainerProgress(getState(), { type, id });

    if (!currentProgress) {
      const progress = await containerProgressSummaryRequest({
        contentType: type,
        contentId: id,
        userId,
      });
      dispatch(progressLoaded({ type, id, progress: progress[type][id] }));
    }
  };
};

export const pathProgressRequested = (id: string) =>
  containerProgressRequested('paths', id);

export const trackProgressRequested = (id: string) =>
  containerProgressRequested('tracks', id);
