import { poll } from './poll';

const minuteMs = 1000 * 60;

// We calculate up-front how long to wait to start the mandatory asset version poll
// Normally, we'll want to wait a while, so it doesn't interfere with page load time performance
// During tests or other special scenarios, we'll wait for a specified (often very small) time
const [pollDelay, assetRequestFrequency] = (() => {
  const frequencyMatch = window.location.search.match(/pollFrequency=(\d+)/);

  if (frequencyMatch) {
    try {
      // If we provide our own frequency, such as from tests, we'll immediately try to load the poll
      return [0, frequencyMatch ? parseInt(frequencyMatch[1], 10) : minuteMs];
    } catch (error) {
      /* Ignore errors from users messing around */
    }
  }

  // By default, we'll wait a minute before loading it, then retry every minute while active
  return [minuteMs, minuteMs];
})();

setTimeout(() => {
  poll.start(assetRequestFrequency);
}, pollDelay);
