import { createAction } from 'redux-actions';

import {
  enrollInPath,
  enrollInPathAndJourneyAndRedirect,
} from '~/libs/api/user';
import { journeyByPathSlug, legacyPathIds } from '~/libs/careerJourneyHelpers';
import { addCompletion } from '~/libs/completionCertificates';
import { logger } from '~/libs/logging/logger';
import { selectPath } from '~/state/entities/paths/selectors';
import { Dispatch, SiteState, ThunkAction } from '~/state/types';
import {
  Completion,
  CompletionContainerType,
  ContainerProgress,
  OnboardingQuestionnaire,
} from '~/typings/entities';
import { ExperimentsConfig } from '~/typings/experiment';
import {
  AppearanceSettingsData,
  ColorModeType,
  UserInterfaceSettings,
} from '~/typings/user';

import {
  selectCurrentUser,
  selectIsPro,
  selectUserCompletion,
} from './selectors';
import { CurrentUser } from './types';

export const readCurrentUserSucceeded = createAction<Partial<CurrentUser>>(
  'CURRENT_USER/READ_SUCCEEDED'
);

export const userAppearanceSettingsUpdateRequested = createAction<AppearanceSettingsData>(
  'CURRENT_USER/APPEARANCE_SETTINGS_UPDATE_REQUESTED'
);

export const userAppearanceSettingsUpdateSucceeded = createAction<AppearanceSettingsData>(
  'CURRENT_USER/APPEARANCE_SETTINGS_UPDATE_SUCCEEDED'
);

export const userAppearanceSettingsUpdateFailed = createAction(
  'CURRENT_USER/APPEARANCE_SETTINGS_UPDATE_FAILED'
);

export const userInterfaceSettingsUpdateRequested = createAction<
  Partial<UserInterfaceSettings>
>('CURRENT_USER/INTERFACE_SETTINGS_UPDATE_REQUESTED');

export const updateUserCompletions = createAction<{ completion: Completion }>(
  'CURRENT_USER/UPDATE_USER_COMPLETIONS'
);

export const onboardingQuestionnaireSucceeded = createAction<
  Partial<OnboardingQuestionnaire>
>('CURRENT_USER/UPDATE_USER_ONBOARDING_QUESTIONNAIRE_ANSWERS');

export const enrollInPathWithRedirect = (
  pathId: string,
  checkoutUrlWithRedirect: string
): ThunkAction => async (dispatch: Dispatch, getState: () => SiteState) => {
  if (!pathId) {
    return;
  }
  const { slug, title: pathName } = selectPath(getState(), pathId);

  await dispatch(enrollInPath(pathId, pathName, slug, checkoutUrlWithRedirect));
};

export const enrollInPathAndJourney = (
  pathId: string,
  checkoutUrlWithRedirect: string
): ThunkAction => async (dispatch: Dispatch, getState: () => SiteState) => {
  if (!pathId) return;

  const { slug: pathSlug, title: pathName, goal: pathGoal } = selectPath(
    getState(),
    pathId
  );
  const currentUser = selectCurrentUser(getState());

  // If this isn't a legacy path, or the user is not pro or user is Business user, just try to enroll in the path directly.
  if (!legacyPathIds.includes(pathId) || currentUser?.isBusinessUser)
    return dispatch(
      enrollInPath(
        pathId,
        pathName,
        pathSlug,
        checkoutUrlWithRedirect,
        pathGoal
      )
    );

  const careerJourney = journeyByPathSlug(pathSlug);

  // If this is a legacy path, and user is pro, enroll them in both legacy path and released journey.
  if (careerJourney)
    return dispatch(
      enrollInPathAndJourneyAndRedirect(
        pathId,
        pathName,
        careerJourney.id,
        careerJourney.slug,
        checkoutUrlWithRedirect
      )
    );
};

export const changeUserColorMode = (mode: ColorModeType) => (
  dispatch: Dispatch
) => {
  dispatch(userAppearanceSettingsUpdateRequested({ color_mode: mode }));
};

export const grantCertificateIfMissing = (
  containerId: string,
  containerType: CompletionContainerType,
  containerProgress: ContainerProgress
): ThunkAction => async (dispatch, getState) => {
  const hasProAccess = selectIsPro(getState());
  const completion = selectUserCompletion(containerId)(getState());
  const hasCertificate = completion && !!completion?.certifiedAt;

  if (hasProAccess && !hasCertificate && containerProgress?.completed) {
    logger.info(
      `missing_certificate: user has a missing certificate for container: ${containerType} ${containerId}`,
      'javascript_logs'
    );

    const newCompletion = await addCompletion({
      containerId,
      containerType,
      date: new Date().toISOString(),
    });
    dispatch(updateUserCompletions({ completion: newCompletion }));
  }
};

export const userExperimentAssignment = createAction<
  Partial<ExperimentsConfig>
>('CURRENT_USER/EXPERIMENT_ASSIGNMENT');
