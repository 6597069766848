import Uri from 'jsuri';

import { ccdata } from '~/libs/ccdata';

export const addParamsAsHash = (
  url: string,
  paramHash: Record<string, boolean | number | string>
) => {
  const uri = new Uri(url);
  Object.keys(paramHash).forEach((k) => {
    if (paramHash[k]) {
      uri.deleteQueryParam(k);
      uri.addQueryParam(k, paramHash[k]);
    }
  });
  return uri.toString();
};

/**
 * @remarks
 * Trailing slashes can compound across redirects and create invalid paths such as "/learn//"
 */
function removeTrailingSlashes(path: string) {
  return path === '/' ? path : path.replace(/(\/+)$/, '');
}

export function normalizeLocationSearch(search?: string) {
  if (!search) return '';
  return `?${search.replace(/\?/g, '')}`;
}

export function createRedirectParam(
  location?: Pick<Location, 'pathname' | 'search'>
) {
  if (!location && typeof window !== 'undefined' && window.location) {
    // eslint-disable-next-line prefer-destructuring
    location = window.location;
  }

  if (!location) {
    return undefined;
  }

  return encodeURIComponent(
    removeTrailingSlashes(
      `${location.pathname}/${normalizeLocationSearch(location.search)}`
    )
  );
}

const isEncoded = (uri: string) => {
  uri = uri || '';
  return uri !== decodeURIComponent(uri);
};

const fullyDecodeURI = (uri: string) => {
  while (isEncoded(uri)) {
    uri = decodeURIComponent(uri);
  }
  return uri;
};

export function safeRedirectUrl(rawUri?: string) {
  if (!rawUri) return '';
  let decodedUri;
  try {
    decodedUri = fullyDecodeURI(rawUri || '');
  } catch (error) {
    decodedUri = rawUri;
  }
  const uri = new Uri(decodedUri);
  const currentUri = new Uri(
    __SSR__ ? ccdata.get('request_href') : window.location.href
  );
  const path = currentUri
    .setPath(uri.path())
    .setHost(__SSR__ ? ccdata.get('request_host') : window.location.hostname)
    .setQuery(uri.query())
    .toString();

  return removeTrailingSlashes(path);
}
