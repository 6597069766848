import { SuperAgentRequest } from 'superagent';

import { ccdata } from '~/libs/ccdata';
import { userAttributes } from '~/libs/userAttributes';

/**
 * superagent csrf plugin
 *
 * sets the csrf header token
 *
 * general usage:
 * import {csrf} from '~/libs/superagent-auth';
 *
 * request
 *      .use(csrf)
 *
 */
export function csrf(request: SuperAgentRequest) {
  const authToken = ccdata.get('authenticity_token');
  if (authToken) {
    request.set('X-CSRF-Token', authToken);
  }
}

/**
 * superagent userAuth plugin
 *
 * sets the user auth token param
 *
 * general usage:
 * import {userAuth} from '~/libs/superagent-auth';
 *
 * request
 *   .use(userAuth)
 *
 */
export function userAuth(request: SuperAgentRequest) {
  if (window.CCDATA!.current_user) {
    request.set(
      'X-Auth-Token',
      window.CCDATA!.current_user.authentication_token
    );
  }
}

/**
 * superagent jwt plugin
 *
 * sets the csrf header token
 *
 * general usage:
 * import {jwtAuth} from '~/libs/superagent-auth';
 *
 * request
 *      .use(jwtAuth)
 *
 */
export function jwtAuth(request: SuperAgentRequest) {
  const token = userAttributes.get('jwt');
  if (token) {
    request.set('Authorization', `Bearer ${token}`);
  }
}
