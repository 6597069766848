/**
 * Attempt to reliably bind a function to be called when the page is hidden or navigated away from
 *
 * @param fn Event callback, should handle cases where it is called more than once, since multiple page hiding events could fire
 * @returns
 */
export const onPageHide = (listener: EventListener): (() => void) => {
  if ('onpagehide' in window) {
    window.addEventListener('pagehide', listener, { capture: true });
    document.addEventListener(
      'visibilitychange',
      (e) => {
        if (document.visibilityState === 'hidden') {
          listener(e);
        }
      },
      { capture: true }
    );
  }

  // only register beforeunload/unload in browsers that don't support
  // pagehide to avoid breaking bfcache, see: https://developers.google.com/web/updates/2018/07/page-lifecycle-api#managing-cross-browsers-differences
  window.addEventListener('unload', listener, { capture: true });
  window.addEventListener('beforeunload', listener, { capture: true });

  return () => {
    window.removeEventListener('unload', listener);
    window.removeEventListener('beforeunload', listener);
    window.removeEventListener('pagehide', listener);
    document.removeEventListener('visibilitychange', listener);
  };
};
