import {
  Completion,
  CompletionContainerType,
  UserCompletion,
} from '~/typings/entities';

import { genericRequest } from './genericRequest';

export const grantsCompletionCertificate = (
  itemId: string,
  certificateDenylistTrackIds?: string[]
) => {
  return Boolean(
    certificateDenylistTrackIds && !certificateDenylistTrackIds.includes(itemId)
  );
};

export const canViewCertificate = (
  item: UserCompletion | Completion,
  certificateDenylistTrackIds?: string[]
) => {
  if (!grantsCompletionCertificate(item.id, certificateDenylistTrackIds)) {
    return false;
  }

  return !!item.certifiedAt;
};

export interface AddCompletionRequestParams {
  containerId: string;
  containerType: CompletionContainerType;
  date: string;
}

export const addCompletion = async ({
  containerId,
  containerType,
  date,
}: AddCompletionRequestParams) => {
  const response = await genericRequest({
    endpoint: `/completions/add_completion`,
    method: 'POST',
    data: {
      container_id: containerId,
      container_type: containerType,
      date,
      achievement: false,
    },
  });

  const { completion } = await response.json();

  return completion;
};
