import { createAction } from 'redux-actions';

export const contentItemProgressSynced = createAction(
  'entities/CONTENT_ITEM_PROGRESS_SYNCED'
);

export interface ReadEntitiesSucceededPayload {
  categories?: any;
  contentItemProgresses?: any;
  contentItems?: any;
  courseProgresses?: any;
  courses?: any;
  journeys?: any;
  modules?: any;
  emodules?: any;
  exercises?: any;
  paths?: any;
  syllabuses?: any;
  tracks?: any;
}

export const readEntitiesSucceeded = createAction<ReadEntitiesSucceededPayload>(
  'entities/READ_ENTITIES_SUCCEEDED'
);
