import { logger } from '~/libs/logging/logger';

const ignoredErrors = ['Script error', 'Script error.'];

class JSErrorLogger {
  errors: { [i: string]: number };

  uniqueErrorCount: number;

  constructor() {
    this.errors = {};
    this.uniqueErrorCount = 0;
    window.addEventListener('error', this.onError);
    window.addEventListener('unhandledrejection', (e) =>
      this.onError(e?.reason)
    );
  }

  onError = (error?: ErrorEvent) => {
    if (!error) return;
    const message = error.message?.replace(/"/g, '\\"');

    // filter out unusable errors
    if (ignoredErrors.some((e) => message === e)) return;

    if (this.errors[message]) {
      this.errors[message] += 1;
    } else {
      this.errors[message] = 1;
    }

    // Disable logging if error has been seen more than x times
    if (this.errors[message] > 2) return;

    logger.error(error);
  };
}

export const startLoggingJSErrors = () => {
  // eslint-disable-next-line no-new
  new JSErrorLogger();
};
