import { Action } from 'redux-actions';
import { call, takeLatest } from 'redux-saga/effects';

import { genericRequest } from '~/libs/genericRequest';
import { progressResetRequestedPayload } from '~/state/entities/containerProgresses/types';

import { progressResetRequested } from './actions';

// this saga is run from the course/path/module syllabus/landing pages.
function* handleProgressResetRequested({
  payload,
}: Action<progressResetRequestedPayload>) {
  if (!payload) {
    return;
  }

  const { resetPath } = payload;

  // call the reset progress backend
  yield call(genericRequest, {
    endpoint: resetPath,
    method: 'POST',
  });
  yield window.location.reload();
}

export function* containerProgressesSagas() {
  yield takeLatest(progressResetRequested, handleProgressResetRequested);
}
