import { createSelector } from 'reselect';

import { addParamsAsHash, safeRedirectUrl } from '~/libs/url';
import { SiteState } from '~/state/types';

import { RouteLocationParams, RouteLocationQuery } from './types';

export const selectLocation = (state: SiteState) => state.location;

export const selectLocationParams = (state: SiteState): RouteLocationParams =>
  state.location?.payload || {};

export const selectLocationQuery = (state: SiteState): RouteLocationQuery =>
  state.location?.query || {};

export const selectLocationType = (state: SiteState) => state.location.type;

export const selectLocationPathName = (state: SiteState) =>
  state.location.pathname;

export const selectLocationPathNameAndQuery = createSelector(
  selectLocationPathName,
  selectLocationQuery,
  (pathName, query) => addParamsAsHash(pathName, query)
);

export const selectIsDraftQuery = createSelector(
  selectLocationQuery,
  (query) => {
    const { draft } = query;
    return draft === 'true';
  }
);

export const selectIsFromDashboard = createSelector(
  selectLocationQuery,
  (query) => query.from_dashboard === 'true'
);

export const selectSafeRedirectUrl = createSelector(
  selectLocationQuery,
  (query) => safeRedirectUrl(encodeURI(query?.redirect_url || ''))
);

export const selectSortingQuizQuestionIndex = createSelector(
  selectLocationQuery,
  (query) => query.currentQuestionIndex
);

export const selectIsPathPageRedirect = createSelector(
  selectLocationQuery,
  (locationQuery) => {
    const redirectUrl = locationQuery.redirect_url;
    return redirectUrl?.includes('/learn/paths/');
  }
);
