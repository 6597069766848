import { AppWrapper } from '@codecademy/gamut';
import { createFontLinks, GamutProvider } from '@codecademy/gamut-styles';
import { CSSObject } from '@codecademy/variance';
import { EmotionCache, Theme } from '@emotion/react';
import React from 'react';
import { Helmet } from 'react-helmet';

interface EmotionOptions {
  variables?: Record<string, CSSObject>;
  theme: Theme;
}

export const withEmotion = (
  Component: React.ComponentType<React.PropsWithChildren<unknown>>,
  options: EmotionOptions
) => {
  return ({ cache, ...props }: { cache: EmotionCache }) => {
    return (
      <GamutProvider cache={cache} {...options}>
        <Helmet>{createFontLinks()}</Helmet>
        <AppWrapper>
          <Component {...props} />
        </AppWrapper>
      </GamutProvider>
    );
  };
};
