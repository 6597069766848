import ErrorStackParser from 'error-stack-parser';
import { isObject } from 'lodash';

import { LogMessage } from './types';

const containsMessage = (error: unknown): error is { message: string } =>
  isObject(error) && 'message' in error;

const errorOrMessage = (error: unknown) => {
  return containsMessage(error) ? `${error.message}` : JSON.stringify(error);
};

export const captureErrorMessageStack = (rawMessage: LogMessage) => {
  // If not an error, create a new one to generate a stack trace
  const [error, stackPosition] =
    rawMessage instanceof Error
      ? [rawMessage, 0]
      : [new Error(errorOrMessage(rawMessage)), 1];

  const fullStack = ErrorStackParser.parse(error);
  const stack = fullStack[stackPosition];
  const { message } = error;

  return {
    error,
    message,
    stack: {
      column: stack.columnNumber,
      file: stack.fileName,
      line: stack.lineNumber,
      sources: fullStack.map((stack) => stack.source).join('\n'),
    },
  };
};
