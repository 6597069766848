import { genericRequest, handleResponse } from '~/libs/genericRequest';
import { logger } from '~/libs/logging/logger';
import { careerJourneyCurriculumPath } from '~/libs/urlHelpers';
import { userAttributes } from '~/libs/userAttributes';
import { AppearanceSettingsData, UserInterfaceSettings } from '~/typings/user';

export interface CourseEnrollRequestData {
  track_id: string;
  module_id: string;
  content_id: string;
}

export const enrollInCourse = async ({
  track_id,
  module_id,
  content_id,
}: CourseEnrollRequestData) => {
  try {
    await genericRequest({
      endpoint: '/learn/enroll',
      method: 'POST',
      data: { container_type: 'track', track_id, module_id, content_id },
    });
  } catch (e) {
    logger.error({
      message: `Unable to create course for user: ${e}`,
    });
  }
};

export const enrollInPath = (
  path_id: string,
  path_name: string,
  pathSlug: string,
  checkoutUrlWithRedirect: string,
  path_goal?: string
) => async () => {
  try {
    const resp = await genericRequest({
      endpoint: '/learn/enroll',
      method: 'POST',
      data: {
        container_type: 'path',
        path_id,
        path_name,
        path_goal,
        path_slug: pathSlug,
      },
    });
    const url = resp.ok ? `/paths/${pathSlug}` : checkoutUrlWithRedirect;
    window.location.assign(url);
  } catch (e) {
    logger.error({
      message: `Unable to create path for user: ${e}`,
    });
  }
};

const enrollInPathInternal = async (
  pathId: string,
  pathName: string,
  redirectStrategy: 'none' | undefined
): Promise<boolean> => {
  try {
    const pathResponse = await genericRequest({
      endpoint: '/learn/enroll',
      method: 'POST',
      data: {
        container_type: 'path',
        path_id: pathId,
        path_name: pathName,
        redirect_strategy: redirectStrategy,
      },
    });

    if (
      !pathResponse.ok &&
      !(await pathResponse.json())?.error.includes('Already enrolled in path')
    ) {
      return false;
    }

    return true;
  } catch (e) {
    return false;
  }
};

export const enrollInJourneyInternal = async (
  journeyId: string
): Promise<boolean> => {
  try {
    const journeyResponse = await genericRequest({
      endpoint: '/learn/enroll',
      method: 'POST',
      data: { container_type: 'journey', journey_id: journeyId },
    });

    if (
      !journeyResponse.ok &&
      !(await journeyResponse.json())?.error.includes(
        'Already enrolled in journey'
      )
    ) {
      return false;
    }

    return true;
  } catch (e) {
    logger.error({
      message: `Unable to enroll user in journey: ${e}`,
    });
    return false;
  }
};

const tryToUnenroll = (id: string, type: string) => {
  return genericRequest({
    endpoint: '/learn/unenroll',
    method: 'POST',
    data: { containers: [{ id, type }] },
  });
};

// This function is only meant to be used with the Full Stack Career Journey experiment,
// as when the experiment is rolled back, we'll want the user to also be enrolled in the path.
export const enrollInPathAndJourneyAndRedirect = (
  pathId: string,
  pathName: string,
  journeyId: string,
  journeySlug: string,
  checkoutUrlWithRedirect: string
) => async () => {
  const journeyEnrolledSuccessfully = await enrollInJourneyInternal(journeyId);
  if (!journeyEnrolledSuccessfully) {
    window.location.assign(checkoutUrlWithRedirect);
    return;
  }

  const pathEnrolledSuccessfully = await enrollInPathInternal(
    pathId,
    pathName,
    'none'
  );
  if (!pathEnrolledSuccessfully) {
    tryToUnenroll(journeyId, 'journey');
    tryToUnenroll(pathId, 'path'); // It can fail after creating the path enrollment so just to be safe...
    window.location.assign(checkoutUrlWithRedirect);
    return;
  }

  window.location.assign(careerJourneyCurriculumPath(journeySlug));
};

interface ExperimentActivationResponse {
  group: string | null;
}

// ts-prune-ignore-next
export const activateExperiment = async (
  experiment: string
): Promise<string | undefined> => {
  try {
    const resp = await genericRequest({
      endpoint: '/experiments/assign',
      method: 'POST',
      data: { use_optimizely: true, experiment_handle: experiment },
    });
    if (!resp.ok) return undefined;

    const responseJson = (await resp.json()) as ExperimentActivationResponse;
    return responseJson.group ?? undefined;
  } catch (e) {
    logger.error({
      message: `Unable to activate experiment for user: ${e}`,
    });
    return undefined;
  }
};

export interface UpdatePasswordRequest {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

export const updatePassword = async (data: UpdatePasswordRequest) => {
  const response = await genericRequest({
    data: {
      current_password: data.currentPassword,
      id: userAttributes.id(),
      password: data.password,
      password_confirmation: data.passwordConfirmation,
    },
    endpoint: `/users/update_password`,
    method: 'POST',
  });

  return {
    ok: response.ok,
    message: (await response.json()).message,
  };
};

export interface UserGoalPreferences {
  targetDays: number | null;
}

export const updateGoalPreferences = async ({
  targetDays,
}: UserGoalPreferences) => {
  return genericRequest({
    data: {
      target_days: targetDays,
    },
    endpoint: `/users/${userAttributes.id()}/target-settings`,
    method: 'PUT',
  }).then(
    handleResponse({
      errorMessage: 'Failed to update goal preferences',
      logAndSwallowErrors: false,
    })
  );
};

export interface UserEmailPreferences {
  codingRemindersOn?: string;
  frequency?: string;
  launchesAndUpdates?: boolean;
  onboarding?: boolean;
  progressUpdates?: boolean;
  promotions?: boolean;
  timeframe?: string;
  timezone?: string;
  unsubscribedFromAll?: boolean;
}

export const updateEmailPreferences = ({
  frequency,
  launchesAndUpdates,
  onboarding,
  progressUpdates,
  promotions,
  unsubscribedFromAll,
  timeframe,
  timezone,
}: Partial<UserEmailPreferences>) => {
  return genericRequest({
    data: {
      coding_reminders_comms_frequency: frequency,
      coding_reminders_comms_timeframe: timeframe,
      coding_reminders_comms_timezone: timezone,
      content_and_feature_comms: launchesAndUpdates,
      deals_and_promotions_comms: promotions,
      mail_silence: unsubscribedFromAll,
      onboarding_comms: onboarding,
      progress_update_comms: progressUpdates,
    },
    endpoint: `/users/${userAttributes.id()}/email-settings`,
    method: 'PUT',
  }).then(
    handleResponse({
      errorMessage: 'Failed to update email preferences',
      logAndSwallowErrors: true,
    })
  );
};

export const updateUserAppearanceSettings = (data: AppearanceSettingsData) => {
  return genericRequest({
    data: {
      color_mode: data.color_mode,
    },
    endpoint: `/users/${userAttributes.id()}/appearance-settings`,
    method: 'PUT',
  })
    .then((response) => ({ response }))
    .catch((error) => ({ error }));
};

export const updateUserInterfaceSettings = (data: UserInterfaceSettings) => {
  return genericRequest({
    data: {
      high_contrast: data.highContrast,
      screen_reader: data.screenReader,
      auto_close_tokens: data.autoCloseTokens,
      render_whitespace: data.renderWhitespace,
      editor_font_size: data.editorFontSize,
      docs_tooltips: data.docsTooltips,
      autocomplete: data.autocomplete,
      dark_mode: data.darkMode,
      ai_assistant: data.aiAssistant,
      additional_guidance_projects: data.additionalGuidanceProjects,
    },
    endpoint: `/users/${userAttributes.id()}/interface-settings`,
    method: 'PUT',
  }).then(
    handleResponse({
      errorMessage: 'Failed to update interface settings',
      logAndSwallowErrors: true,
    })
  );
};
