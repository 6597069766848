import { createSelector } from 'reselect';

import { SiteState } from '~/state/types';

import { ReleasedContentState } from './types';

export const selectReleasedContent = (state: SiteState) =>
  state.releasedContent;

export const selectReleasedPathIds = createSelector(
  selectReleasedContent,
  ({ path_ids }: ReleasedContentState) => path_ids
);

export const selectCertificateDenylistTrackIds = createSelector(
  selectReleasedContent,
  ({ certificate_denylist_track_ids }: ReleasedContentState) =>
    certificate_denylist_track_ids
);

export const selectTrackHasCertificate = createSelector(
  selectCertificateDenylistTrackIds,
  (_: SiteState, id: string) => id,
  (denyList, id: string) => Boolean(denyList && !denyList.includes(id))
);
