import isMobile from 'ismobilejs';

import { isChromeOS } from './device';
import { isKnownSlowNetwork } from './network';

export async function register() {
  if (
    process.env.NODE_ENV !== 'production' ||
    !('serviceWorker' in navigator) ||
    (!isChromeOS() && (isMobile().any || isKnownSlowNetwork()))
  ) {
    return;
  }

  const { Workbox } = await import('workbox-window');
  const wb = new Workbox(`/service-worker.js`);

  wb.addEventListener('activated', async (event) => {
    if (!event.isUpdate) {
      wb.messageSW({ type: 'CLIENTS_CLAIM' });
    }
  });

  wb.addEventListener('installed', () => {
    wb.messageSkipWaiting();
  });

  wb.register();
}

export async function unregister() {
  try {
    return await navigator.serviceWorker
      ?.getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
  } catch {
    // If the user's browser doesn't allow service workers then there's nothing for us to do
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1429714
  }
}
