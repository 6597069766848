/**
 * navigator.sendBeacon
 * uses sendBeacon if it exists, and normal sync fetch if it doesn't
 *
 * usage:
 * import { beacon } from '~/libs/beacon';
 *
 * beacon('/my_logging_endpoint', data);
 */

import { logger } from './logging/logger';
import { userAttributes } from './userAttributes';

const browserSupportsKeepalive = () => 'keepalive' in Request.prototype;

export const beacon = async (url: string, data: FormData) => {
  try {
    if (__SSR__) return Promise.resolve();
    data.append('authentication_token', userAttributes.authenticationToken());

    if (navigator.sendBeacon?.(url, data)) return Promise.resolve();

    // fallback to standard fetch if sendBeacon fails
    const fetchOptions: RequestInit = {
      method: 'POST',
      body: data,
    };
    if (browserSupportsKeepalive()) {
      fetchOptions.keepalive = true;
    }
    return await fetch(url, fetchOptions);
  } catch (error) {
    logger.error(`Error in beacon: ${error}`);
  }
};
