import 'focus-visible';
import 'react-app-polyfill/stable';
import '~/libs/mandatoryAssetVersionPoll';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { startLoggingJSErrors } from '~/libs/logging/JSErrors';

const DATADOG_CLIENT_TOKEN = 'pub4b4c157b8033dcd97e14c779a3ca222c';

const ddEnabledEnvs = ['staging', 'production', 'dev'];
if (ddEnabledEnvs.includes(CCDATA.env) && CCDATA.dd_service) {
  datadogRum.init({
    applicationId: 'cba56357-90aa-41e2-804e-edb273a29cfe',
    clientToken: DATADOG_CLIENT_TOKEN,
    service: CCDATA.dd_service,
    env: CCDATA.env,
    version: CCDATA.dd_version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: CCDATA.current_user ? 60 : 10,
    trackUserInteractions: true,
  });
  if (CCDATA.current_user) {
    datadogRum.setUser({
      id: CCDATA.current_user._id,
    });
  }
  // In portal app, we set startSessionReplayRecordingManually: true
  // and call datadogRum.startSessionReplayRecording() when !isOnServer()
  // Here, we don't set startSessionReplayRecordingManually, and allow
  // sessions to record automatically, which is the default in sdk v5.
}

if (ddEnabledEnvs.includes(CCDATA.env)) {
  datadogLogs.init({
    forwardErrorsToLogs: false,
    clientToken: DATADOG_CLIENT_TOKEN,
    version: CCDATA.dd_version,
    env: CCDATA.env,
    service: CCDATA.dd_service,
  });
}

startLoggingJSErrors();

import(/* webpackChunkName: 'GTMUserSignUp' */ '~/libs/gtm/userSignUp').then(
  ({ userSignUp }) => {
    userSignUp.track();
  }
);

// If you're looking at this code in dev, you probably don't need this message 😉
if (!__DEV__) {
  // eslint-disable-next-line no-console
  console.log(`👋 Hi there!
While you're here, how about popping over to https://codecademy.com/about/careers?
We're hiring for passionate, talented engineers, and would love to hear from you! ✨
`);
}
