import { createSelector } from 'reselect';

import {
  selectIsProGold,
  selectIsProSilver,
} from '~/state/currentUser/selectors';
import { selectAllModules } from '~/state/entities/modules/selectors';
import { selectPathBySlug } from '~/state/entities/paths/selectors';
import { SiteState } from '~/state/types';
import { Track } from '~/typings/entities/track';

export const selectAllTracks = (state: SiteState) =>
  state.entities.tracks.byId || {};

export const selectTrack = createSelector(
  selectAllTracks,
  (_: SiteState, id: string) => id,
  (tracks, id) => {
    return tracks[id];
  }
);

export const selectTracks = createSelector(
  selectAllTracks,
  (_: SiteState, ids: string[]) => ids,
  (tracks, ids = []) => {
    return ids.map((id) => tracks[id]).filter((track) => track);
  }
);

export const selectTrackBySlug = createSelector(
  selectAllTracks,
  (_: SiteState, slug: string) => slug,
  (tracks, slug) => {
    return Object.values(tracks).find((tr) => tr.slug === slug);
  }
);

export const selectTrackModules = createSelector(
  (state: SiteState, id: string) => selectTrack(state, id),
  selectAllModules,
  (track, modules) => {
    if (!track?.module_ids) return [];

    const trackModules = track.module_ids
      .map((moduleId) => modules[moduleId])
      .filter(Boolean);
    return trackModules;
  }
);

export const selectTrackContentItemIds = createSelector(
  (state: SiteState, id: string) => selectTrackModules(state, id),
  (trackModules) => {
    const trackContentItemIds = trackModules
      .reduce<string[]>(
        (acc, module) => acc.concat(module.content_item_ids),
        []
      )
      .filter(Boolean);
    return trackContentItemIds;
  }
);

export const trackPartnerSelector = (track: Track) => {
  const {
    partner_logo: logo,
    partner_tagline: tagline,
    partner_url: link,
  } = track;
  if (logo || tagline || link) {
    return { logo, tagline, link };
  }
  return null;
};

interface TrackAccessParams {
  trackId?: string;
  pathSlug?: string;
}

export const selectHasTrackAccess = createSelector(
  selectIsProGold,
  selectIsProSilver,
  (state: SiteState, { pathSlug }: TrackAccessParams) =>
    pathSlug && selectPathBySlug(state, pathSlug),
  (_: SiteState, { trackId }: TrackAccessParams) => trackId,
  (isProGold, isProSilver, currentPath, trackId) => {
    const { goal, track_ids } = currentPath || {};

    if (isProGold || (isProSilver && goal === 'skill')) {
      return true;
    }

    return track_ids?.[0] === trackId;
  }
);
