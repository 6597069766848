import { handleActions } from 'redux-actions';

import { OnboardingQuestionnaire } from '~/typings/entities';

import * as actions from './actions';
import * as streakActions from './streaks/actions';
import { CurrentUser, WeeklyStreakData } from './types';

const defaultState: CurrentUser = {
  canUpgradePlan: false,
  achievements: [],
  courseEnrollments: [],
  email: '',
  experiments: {},
  handle: '',
  id: '',
  interfaceSettings: {
    highContrast: false,
    screenReader: false,
    autoCloseTokens: true,
    renderWhitespace: false,
    editorFontSize: 'reg' as const,
    autocomplete: false,
    darkMode: false,
    aiAssistant: true,
    additionalGuidanceProjects: false,
  },
  journeyEnrollments: [],
  location: {
    geo_country: '',
    in_eu: false,
  },
  name: '',
  onboardingQuestionnaire: undefined,
  pathEnrollments: [],
  peerMatch: {
    discordChatUrl: '',
    peer: { avatarUrl: '', userId: '', username: '' },
    project: '',
  },
  profileImage: '',
  roles: {},
  subscription: {},
  username: '',
  weeklyStreaks: {
    achievedDaysThisWeek: [],
    currentWeeklyStreak: 0,
    longestWeeklyStreak: 0,
    startDay: '',
    targetDays: 0,
    weeksCompleted: [],
  },
  oneTimeTriggers: [],
  completions: {},
  organizations: {},
  canAccessCareerPaths: true,
  appearanceSettings: {
    color_mode: 'light',
  },
  isGold: false,
  isSilver: false,
  totalEnrollments: 0,
};

export const currentUser = handleActions<CurrentUser, {}>(
  {
    [`${actions.readCurrentUserSucceeded}`]: (
      state,
      action: ReturnType<typeof actions.readCurrentUserSucceeded>
    ) => ({
      ...state,
      ...action.payload,
    }),
    [`${streakActions.weeklyTargetSet}`]: (
      state,
      { payload }: ReturnType<typeof streakActions.weeklyTargetSet>
    ) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        weeklyStreaks: {
          ...state.weeklyStreaks,
          targetDays: payload?.targetDays || 0,
        } as WeeklyStreakData,
      };
    },
    [`${streakActions.weeklyStreakCelebrated}`]: (
      state,
      { payload }: ReturnType<typeof streakActions.weeklyStreakCelebrated>
    ) => {
      if (!payload) {
        return state;
      }
      return {
        ...state,
        weeklyStreaks: {
          ...state.weeklyStreaks,
          lastCelebratedWeek: payload?.lastCelebratedWeek || 0,
        } as WeeklyStreakData,
      };
    },
    [`${streakActions.readStreakDataSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof streakActions.readStreakDataSucceeded>
    ) => {
      if (!payload) {
        return state;
      }
      const newState = {
        ...state,
        weeklyStreaks: {
          ...state.weeklyStreaks,
          ...payload,
        } as WeeklyStreakData,
      };
      return newState;
    },
    [`${actions.updateUserCompletions}`]: (
      state,
      { payload }: ReturnType<typeof actions.updateUserCompletions>
    ) => {
      const newCompletion = payload?.completion;
      if (!newCompletion) return state;

      return {
        ...state,
        completions: {
          ...state.completions,
          [newCompletion.containerId]: newCompletion,
        },
      };
    },
    [`${actions.onboardingQuestionnaireSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof actions.onboardingQuestionnaireSucceeded>
    ) => {
      if (!payload) return state;

      const newState = {
        ...state,
        onboardingQuestionnaire: {
          ...state.onboardingQuestionnaire,
          ...payload,
        } as OnboardingQuestionnaire,
      };
      return newState;
    },
    [`${actions.userAppearanceSettingsUpdateRequested}`]: (state) => {
      return {
        ...state,
      };
    },
    [`${actions.userAppearanceSettingsUpdateSucceeded}`]: (
      state,
      { payload }
    ) => {
      return {
        ...state,
        appearanceSettings: {
          ...state.appearanceSettings,
          ...payload,
        },
      };
    },
    [`${actions.userAppearanceSettingsUpdateFailed}`]: (state) => {
      return {
        ...state,
      };
    },
    [`${actions.userExperimentAssignment}`]: (
      state,
      { payload }: ReturnType<typeof actions.userExperimentAssignment>
    ) => {
      return {
        ...state,
        experiments: {
          ...state.experiments,
          ...payload,
        },
      };
    },
  },
  defaultState
);
