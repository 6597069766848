import { call, select, spawn } from 'redux-saga/effects';

import { logger } from '~/libs/logging/logger';
import * as serviceWorkerRegistration from '~/libs/serviceWorkerRegistration';
import { selectIsServiceWorkerEnabled } from '~/state/selectors';

import { currentUserSagas } from './currentUser/sagas';
import { entitiesSagas } from './entities/sagas';
import { notificationsSagas } from './notifications/sagas';
import { pageVisibilitySagas } from './page-visibility/sagas';

export function* serviceWorkerRegistrationSaga() {
  if (__SSR__) return;

  const serviceWorkerEnabled: boolean = yield select(
    selectIsServiceWorkerEnabled
  );

  try {
    if (serviceWorkerEnabled) {
      yield serviceWorkerRegistration.register();
    } else {
      yield serviceWorkerRegistration.unregister();
    }
  } catch (err) {
    const swAction = serviceWorkerEnabled ? 'register' : 'unregister';
    logger.error(`Failed to ${swAction} service worker`);
    logger.error(err);
  }
}

export default function* rootSaga() {
  // Use spawn to prevent any error in one saga from crashing the others
  yield spawn(serviceWorkerRegistrationSaga);
  yield spawn(notificationsSagas);
  yield spawn(pageVisibilitySagas);
  yield spawn(entitiesSagas);
  yield call(currentUserSagas);
}
