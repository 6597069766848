import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import {
  updateEmailPreferences,
  updateUserAppearanceSettings,
  updateUserInterfaceSettings,
} from '~/libs/api/user';
import { logger } from '~/libs/logging/logger';
import { ActionData } from '~/state/types';
import { UserInterfaceSettings } from '~/typings/user';

import { addAlert } from '../alerts/actions';
import {
  readCurrentUserSucceeded,
  userAppearanceSettingsUpdateFailed,
  userAppearanceSettingsUpdateRequested,
  userAppearanceSettingsUpdateSucceeded,
  userInterfaceSettingsUpdateRequested,
} from './actions';
import {
  selectIsAnonymous,
  selectUserHasSetTimezone,
  selectUserInterfaceSettings,
} from './selectors';

function* checkForTimezone() {
  const userHasSetTimeZone: boolean = yield select(selectUserHasSetTimezone);

  const userIsAnon: boolean = yield select(selectIsAnonymous);

  if (!userHasSetTimeZone && !userIsAnon) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    try {
      yield call(updateEmailPreferences, { timezone });
    } catch (error) {
      logger.error({
        message: error.message,
      });
    }
  }
}

export function* handleUpdateUserAppearanceSettings({
  payload,
}: ActionData<typeof userAppearanceSettingsUpdateRequested>) {
  try {
    yield call(updateUserAppearanceSettings, payload);
    yield put(userAppearanceSettingsUpdateSucceeded(payload));
  } catch (error) {
    logger.error({
      message: error.message,
    });
    yield put(userAppearanceSettingsUpdateFailed());
  }
}

function* handleUpdateUserAppearanceSettingsFailed() {
  yield put(
    addAlert({
      type: 'error',
      message: 'Failed to update appearance settings',
    })
  );
}

function* handleUpdateUserAppearanceSettingsSucceeded() {
  yield put(
    addAlert({ type: 'general', message: 'Appearance settings updated' })
  );
}

export function* handleUserInterfaceSettingsUpdateRequested({
  payload,
}: ActionData<typeof userInterfaceSettingsUpdateRequested>) {
  const interfaceSettings: UserInterfaceSettings = yield select(
    selectUserInterfaceSettings
  );

  yield put(
    readCurrentUserSucceeded({
      interfaceSettings: {
        ...interfaceSettings,
        ...payload,
      },
    })
  );
  yield call(updateUserInterfaceSettings, { ...interfaceSettings, ...payload });
}

export function* currentUserSagas() {
  yield all([
    call(checkForTimezone),
    takeEvery(
      userInterfaceSettingsUpdateRequested as any,
      handleUserInterfaceSettingsUpdateRequested
    ),
    takeEvery(
      userAppearanceSettingsUpdateRequested as any,
      handleUpdateUserAppearanceSettings
    ),
    takeEvery(
      userAppearanceSettingsUpdateFailed as any,
      handleUpdateUserAppearanceSettingsFailed
    ),
    takeEvery(
      userAppearanceSettingsUpdateSucceeded as any,
      handleUpdateUserAppearanceSettingsSucceeded
    ),
  ]);
}
