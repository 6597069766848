import { createAction } from 'redux-actions';

import { genericRequest, handleResponse } from '~/libs/genericRequest';
import { userAttributes } from '~/libs/userAttributes';
import { Dispatch, SiteThunkAction } from '~/state/types';

import { WeeklyStreakData } from '../types';
import type {
  WeeklyStreaksCelebratedPayload,
  WeeklyTargetSetPayload,
} from './types';

export const weeklyStreakCelebrated = createAction<WeeklyStreaksCelebratedPayload>(
  'weeklyStreaks/WEEK_CELEBRATED'
);

export const weeklyTargetSet = createAction<WeeklyTargetSetPayload>(
  'weeklyStreaks/TARGET_SET'
);

export const readStreakDataSucceeded = createAction<WeeklyStreakData>(
  'entities/READ_STREAK_DATA_SUCCEEDED'
);

export const getUserStreakData = async () => {
  return genericRequest({
    endpoint: `/users/${userAttributes.id()}/weekly-streaks`,
    method: 'GET',
  }).then(
    handleResponse<WeeklyStreakData>({
      errorMessage: 'Failed to get user weekly streaks data',
      logAndSwallowErrors: true,
    })
  );
};

export const fetchUserStreakData = (): SiteThunkAction => async (
  dispatch: Dispatch
) => {
  const userStreakData = await getUserStreakData();

  dispatch(readStreakDataSucceeded(userStreakData));
};
