import React from 'react';

import { logger } from '~/libs/logging/logger';

export interface ErrorBoundaryProps {
  fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError?: true;
}

export class ErrorBoundary extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, extraInfo?: React.ErrorInfo) {
    logger.error(error, extraInfo?.componentStack);
  }

  render() {
    return this.state?.hasError
      ? this.props.fallback || null
      : this.props.children;
  }
}

export function withErrorBoundary<Props>(
  WrappedComponent: React.ComponentType<React.PropsWithChildren<Props>>,
  fallback?: React.ReactNode
) {
  const WithBoundaryComponent: React.FC<React.PropsWithChildren<Props>> = (
    props
  ) => (
    <ErrorBoundary fallback={fallback}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );

  return WithBoundaryComponent;
}
