// TODO[Journey]: We'll likely need this until we deprecate the legacy career paths.
export const LEGACY_FULL_STACK_PATH_ID = '5f7e644d833c070013ef47c4';
const LEGACY_FRONT_END_PATH_ID = '5f85dd867b67b60014ac9ea3';
const LEGACY_BACK_END_PATH_ID = '5f8f903b4c5f2700128bf8ea';
const LEGACY_COMPUTER_SCIENCE_PATH_ID = '5b5001a11d176d7acd8b2bf8';
const LEGACY_DATA_SCIENCE_ANALYTICS_PATH_ID = '5ff882c953c2ed000e17bf1d';
const LEGACY_DATA_SCIENCE_MACHINE_LEARNING_PATH_ID = '5b520caa1d176d21f5a65a61';

export const RELEASED_FULL_STACK_JOURNEY_ID =
  'ffd0f42cce1a44e9a0108b365047a0a6';
const RELEASED_FULL_STACK_JOURNEY_SLUG = 'full-stack-engineer';
const RELEASED_FRONT_END_JOURNEY_ID = '2682884a0719474f96407efe432fdd87';
const RELEASED_FRONT_END_JOURNEY_SLUG = 'front-end-engineer';
const RELEASED_BACK_END_JOURNEY_ID = '810f53d14de24b938cc13c7c2af1e686';
const RELEASED_BACK_END_JOURNEY_SLUG = 'back-end-engineer';
const RELEASED_COMPUTER_SCIENCE_JOURNEY_ID = '05009c20e9174378acd37e6c2d0fbfc4';
const RELEASED_COMPUTER_SCIENCE_JOURNEY_SLUG = 'computer-science';
const RELEASED_DATA_SCIENCE_ANALYTICS_JOURNEY_ID =
  '7dec503730a448c8b22bb251ada403f3';
const RELEASED_DATA_SCIENCE_ANALYTICS_JOURNEY_SLUG = 'data-scientist-aly';
const RELEASED_DATA_SCIENCE_MACHINE_LEARNING_JOURNEY_ID =
  '8e9e59de3f924b33ad2371faf667129b';
const RELEASED_DATA_SCIENCE_MACHINE_LEARNING_JOURNEY_SLUG = 'data-scientist-ml';

const fullStackJourney: { id: string; slug: string } = {
  id: RELEASED_FULL_STACK_JOURNEY_ID,
  slug: RELEASED_FULL_STACK_JOURNEY_SLUG,
};

const frontEndJourney: { id: string; slug: string } = {
  id: RELEASED_FRONT_END_JOURNEY_ID,
  slug: RELEASED_FRONT_END_JOURNEY_SLUG,
};

const backEndJourney: { id: string; slug: string } = {
  id: RELEASED_BACK_END_JOURNEY_ID,
  slug: RELEASED_BACK_END_JOURNEY_SLUG,
};

const computerScienceJourney: { id: string; slug: string } = {
  id: RELEASED_COMPUTER_SCIENCE_JOURNEY_ID,
  slug: RELEASED_COMPUTER_SCIENCE_JOURNEY_SLUG,
};

const dataScienceAnalyticsJourney: { id: string; slug: string } = {
  id: RELEASED_DATA_SCIENCE_ANALYTICS_JOURNEY_ID,
  slug: RELEASED_DATA_SCIENCE_ANALYTICS_JOURNEY_SLUG,
};

const dataScienceMachineLearningJourney: { id: string; slug: string } = {
  id: RELEASED_DATA_SCIENCE_MACHINE_LEARNING_JOURNEY_ID,
  slug: RELEASED_DATA_SCIENCE_MACHINE_LEARNING_JOURNEY_SLUG,
};

export const pathToJourneyMap: Record<string, { id: string; slug: string }> = {
  'full-stack-engineer-career-path': fullStackJourney,
  'front-end-engineer-career-path': frontEndJourney,
  'back-end-engineer-career-path': backEndJourney,
  'computer-science': computerScienceJourney,
  'data-analyst': dataScienceAnalyticsJourney,
  'data-science': dataScienceMachineLearningJourney,
};

export const journeyToPathIdMap: Record<string, string> = {
  ffd0f42cce1a44e9a0108b365047a0a6: LEGACY_FULL_STACK_PATH_ID,
  '2682884a0719474f96407efe432fdd87': LEGACY_FRONT_END_PATH_ID,
  '810f53d14de24b938cc13c7c2af1e686': LEGACY_BACK_END_PATH_ID,
  '05009c20e9174378acd37e6c2d0fbfc4': LEGACY_COMPUTER_SCIENCE_PATH_ID,
  '7dec503730a448c8b22bb251ada403f3': LEGACY_DATA_SCIENCE_ANALYTICS_PATH_ID,
  '8e9e59de3f924b33ad2371faf667129b': LEGACY_DATA_SCIENCE_MACHINE_LEARNING_PATH_ID,
};

export const legacyPathIds = Object.values(journeyToPathIdMap);

export const journeyByPathSlug = (
  pathSlug: string
): { id: string; slug: string } | undefined => {
  return pathToJourneyMap[pathSlug];
};

export const careerPathHasJourney = (pathSlug: string) => {
  return !!pathToJourneyMap[pathSlug];
};
