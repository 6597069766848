import { handleActions } from 'redux-actions';

import { addEntities } from '~/libs/state/entityHelpers';
import { readEntitiesSucceeded } from '~/state/entities/actions';

const defaultState = {
  byId: {},
  idsBySlug: {},
};

export const categories = handleActions(
  {
    [`${readEntitiesSucceeded}`]: (
      state,
      { payload }: ReturnType<typeof readEntitiesSucceeded>
    ) => {
      if (!payload || !payload.categories) return state;
      if (payload.categories.byId) {
        return addEntities(state, payload.categories.byId, {
          idKey: 'id',
          alternateKey: 'slug',
        });
      }
      return addEntities(state, payload.categories, { idKey: 'id' });
    },
  },
  defaultState
);
