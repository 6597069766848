import { BillingInfo } from 'recurly__recurly-js';

import { ContentfulCheckoutPage } from '~/typings/contentful';
import {
  BusinessPaymentPlan,
  CardBrand,
  CouponStatus,
  Currency,
  CurrencySymbol,
  PaymentMethod,
  PaymentMethodBusiness,
  PaymentPlan,
  PaymentPlans,
  PlanCoupon,
  PlanType,
} from '~/typings/payment';

export interface FlashMessage {
  level: string;
  text: string;
  id?: string;
}

export interface PaymentState {
  availablePlans?: PaymentPlans;
  pathUpsellContentfulData?: ContentfulCheckoutPage;
  coupon?: PlanCoupon;
  couponError?: string;
  couponStatus?: CouponStatus;
  currentPlan?: PaymentPlan;
  isUSA?: boolean;
  isBusiness?: boolean;
  businessPaidPlan?: BusinessPaymentPlan;
  recurlyKey: string;
  status?: string;
  error?: string;
  errorFields?: string[];
  flashMessages?: FlashMessage[];
  updatePaymentInfoToken: string;
  selectedPlanId: string;
  subscription?: Subscription;
  subscriptions: BillingSubscription[];
  tax?: PlanTax;
  trialPricing?: TrialPricing;
  umbrellas: Record<string, Umbrella>;
  form: {
    countryCode: string;
    zip: string;
    firstName: string;
    lastName: string;
  };
  bankDebitForm?: BankDebitForm;
  subscriptionTermsOfService: string;
  paypalRedirectNotice: string;
  paypalAuth: string;
  availablePayMethods: PaymentMethod[];
  availableBusinessPaymentMethods: PaymentMethodBusiness[];
  payMethod?: string;
  studentVerification?: {
    showStudentPlans: boolean;
    backToSchoolProgramId: string;
    verificationStep: string;
    verificationId: string;
    isVerifiedStudent: boolean;
  };
  planId?: string;
  acceptedTermsAndPolicy?: {
    creditcard: boolean;
    paypal: boolean;
    bankdebit: boolean;
  };
  previewSubscriptionFields?: PaymentStatePreviewSubscriptionFields;
  businessPreviewStatus?: string;
  currentTab?: 'creditcard' | 'paypal' | 'bankdebit';
  isBusinessUpsell?: boolean;
  is_upsell?: boolean;
  isEditingBusinessQuantity?: boolean;
  isPaymentSuccessful?: boolean;
  isBusinessFormFieldError?: boolean;
  isZipCodeFilled?: boolean;
  isBusinessTaxExempt?: boolean;
  paytmCheckoutScript?: string;
  paytmForm: {
    firstName: string;
    lastName: string;
    pinCode: string;
  };
}

export interface PaymentStatePreviewSubscriptionFields {
  balance_in_cents: number;
  total_amount_in_cents: number;
  quantity: number;
  subtotal_amount_in_cents?: number;
  provider_id?: string;
  discount_in_cents?: number;
  plan_code?: string;
  account_code?: string;
  currency?: Currency;
  auto_renew?: boolean;
  current_period_ends_at: string;
}

// PBS invoices have a pdfUrl, non-PBS have an invoice number and are routed
// through the monolith.
export interface Invoice {
  amountInCents: number;
  transactionAmountInCents: number;
  closedAt: string;
  createdAt: string;
  currency: Currency;
  description: string;
  productCode: string;
  providerId: string;
  state: string;
  type: string;
  formattedTotal: string;
  invoiceNumber: number;
  pdfUrl?: string;
  provider?: string;
}

export type BankDebitForm = BillingInfo & {
  account_type?: string;
  sort_code: string;
};

export interface PlanTax {
  amount: number;
  error: boolean;
}

export interface PlanTaxResult {
  amount?: number;
  error: boolean;
}

export interface TrialPricing {
  currencySymbol: CurrencySymbol;
  formattedMonthlyPrice: string;
}

export type Subscription = Partial<BillingSubscriptionRequired> & {
  canUpgradeSub?: boolean;
  currency: Currency;
  discountInCents?: number;
  id: string;
  isPaymentPastDue?: boolean;
  hasPendingChange?: boolean;
  pendingChange?: { table: Record<string, any> };
  previousAmountInCents?: number;
  canPauseSub?: boolean;
  canUnpauseSub?: boolean;
  isProPaused?: boolean;
  isProScheduledToPause?: boolean;
  lastPayment?: number;
  lastPaymentDate?: string;
  name: string;
  period: 'month' | 'six_months' | 'year';
  plan: PaymentPlan;
  planType?: PlanType;
  provider:
    | PaymentProvider.RECURLY
    | PaymentProvider.PAYTM
    | PaymentProvider.STRIPE
    | PaymentProvider.ONETIME;
  providerId: string;
  sourceDetails: SubscriptionPaymentMethod;
  status: SubscriptionStatus;
  subtotalInCents: number;
  taxInCents: number;
  totalInCents: number;
  unitAmountInCents: number;
  trialActive: boolean;
  isStudentPlan: boolean;
  activeCouponRedemptionAmounts: number[];
  canBeResumed?: boolean;
  isLapsing?: boolean;
};

export enum SubscriptionStatus {
  ACTIVE = 'active',
  SUSPENDED = 'suspended',
  CANCELED = 'canceled',
  EXPIRED = 'expired',
}

export enum PaymentProvider {
  GOOGLE = 'google',
  RECURLY = 'recurly',
  PAYTM = 'paytm',
  STRIPE = 'stripe',
  ONETIME = 'onetime',
}

export type EitherBillingSubscription =
  | GoogleSubscription
  | BillingSubscription;

export interface GoogleSubscription {
  createdAt: string;
  provider: PaymentProvider.GOOGLE;
}

export type BillingSubscription = Subscription & BillingSubscriptionRequired;

interface BillingSubscriptionRequired {
  isScheduledToPause: boolean;
  resumeAt: string;
  pausedAt: string;
  periodEndDt: string;
  periodStartDt: string;
}

export interface SubscriptionPaymentMethod {
  type: PaymentMethod;
  email: string;
  last4: string;
  zip: string;
  countryCode: string;
  brand?: CardBrand;
  provider:
    | PaymentProvider.RECURLY
    | PaymentProvider.PAYTM
    | PaymentProvider.STRIPE
    | PaymentProvider.ONETIME;
}

export interface Umbrella {
  uuid: string;
  price: string;
  priceFormatted: number;
}

export interface PurchaseOptions {
  planId: string;
  currency: Currency;
  redirectUrl: string;
  plan: PaymentPlan;
}
