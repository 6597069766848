/**
 * A caught error to be logged by logger.error.
 */
export class LoggerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

/**
 * A caught warning to be logged by logger.warn.
 */
export class LoggerWarning extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
  }
}
