import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectActiveFeatures } from '~/state/features/selectors';
import { FeatureFlagNames } from '~/state/features/types';
import { ExperimentsConfig } from '~/typings/experiment';

import { selectCurrentUserExperiments } from '../currentUser/selectors';

export function useSetDatadogContext() {
  const features = useSelector(selectActiveFeatures);
  const experiments = useSelector(selectCurrentUserExperiments);
  useEffect(() => {
    addFeaturesAndExperimentsToDatadog(features, experiments);
  }, [features, experiments]);
}

/* This function sends all active features and experiments to datadog. For each active feature, datadog receives an object that contains feature_name: true. For experiments, datadog receives one object with the experiment name and group */
export const addFeaturesAndExperimentsToDatadog = (
  features: FeatureFlagNames[],
  experiments: Partial<ExperimentsConfig> | undefined
) => {
  const featuresObj = features.reduce<Record<string, string | boolean>>(
    (obj, featureName) => {
      obj[featureName] = true;
      return obj;
    },
    {}
  );

  datadogRum.setGlobalContextProperty('feature_flags', {
    ...featuresObj,
  });

  datadogRum.setGlobalContextProperty('experiments', experiments);
};
