import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { ccdata } from '~/libs/ccdata';
import { sessionId } from '~/libs/logging/sessionId';

import { captureErrorMessageStack } from './captureErrorMessageStack';
import { LoggerError, LoggerWarning } from './errors';
import { LogData, LogFile, LogLevel, LogMessage } from './types';

function paramaterize(data: LogData) {
  let str = '';
  // eslint-disable-next-line guard-for-in
  for (const key in data) {
    // eslint-disable-next-line eqeqeq
    if (str != '') {
      str += '&';
    }
    str += `${key}=${encodeURIComponent(data[key]!)}`;
  }
  return str;
}

function stringify(data: LogData, order: string[]) {
  return order
    .map((key) => {
      let value = data[key];
      if (value === undefined) value = '';
      return `${key}="${value}"`;
    })
    .join(' ');
}

function send(
  level: LogLevel,
  data: LogData,
  order = Object.keys(data).sort(),
  logfile?: LogFile
) {
  const stringifiedData = stringify(data, order);
  const endpoint = `//${window.location.host}/logs/v1/`;

  const metadata = stringify(
    {
      ua: window.navigator.userAgent,
      location: window.location.pathname,
      utype: CCDATA.current_user ? 'r' : 'a',
      uid: CCDATA.current_user?._id || CCDATA.anonymous_user?._id || 'null',
      backend: 'container_api',
      country:
        CCDATA.current_user?.location.geo_country ||
        CCDATA.anonymous_user?.location.geo_country ||
        'null',
    },
    ['ua', 'location', 'utype', 'uid', 'backend', 'country']
  );

  const img = document.createElement('img');
  const payload = {
    level,
    data: stringifiedData,
    metadata,
    logfile,
    date: new Date().valueOf(),
    session: sessionId(),
  };

  img.src = `${endpoint}?${paramaterize(payload)}`;
}

const createBasicLoggerMethod = (level: LogLevel) => (
  data: LogData | string,
  logfile: LogFile,
  order?: string[]
) => {
  if (typeof data === 'string') {
    data = { message: data };
  }

  if (['development', 'test'].includes(ccdata.get('env'))) {
    console[level](`${logfile}.log`, data); // eslint-disable-line
  }

  if (level === 'info') {
    datadogLogs.logger.info(data.message as string, data);
  } else {
    send(level, data, order, logfile);
  }

  return true;
};

const errorLogger = createBasicLoggerMethod('error');
const warnLogger = createBasicLoggerMethod('warn');

/**
 * Public error reporter.
 *
 * Sends an event to DataDog and ensures that there is a stack trace for debugging.
 */
export const logger = {
  info: createBasicLoggerMethod('info'),
  error: (rawError: LogMessage, extraInfo?: string | number | boolean) => {
    const { message, stack } = captureErrorMessageStack(rawError);
    const data = {
      message,
      ...stack,
      ...{ extraInfo },
    };

    errorLogger(data, 'javascript_errors');
    datadogRum.addError(new LoggerError(message), data);
  },

  warn: (warning: LogMessage) => {
    const { message, stack } = captureErrorMessageStack(warning);
    const data = { message, ...stack };

    warnLogger(data, 'javascript_warnings');
    datadogRum.addError(new LoggerWarning(message), data);
  },
};
