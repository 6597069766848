import { beacon } from './beacon';
import { onPageHide } from './pageVisibility';

const isBrowser =
  typeof window !== 'undefined' && process.env.NODE_ENV !== 'test';
const ENDPOINT = '/stats/v1/batch';

export interface StatsdClientOptions {
  flushInterval?: number;
  flushUrl?: string;
  queueLimit?: number;
  stopped?: boolean;
}

export interface StatsdStat {
  name: string;
  payload: StatsdPayload;
}

export interface StatsdPayload {
  measurement: unknown;
  action: unknown;
  value?: unknown;
}

interface StatsdClientConstructor {
  new (options: StatsdClientOptions): StatsdClientInstance;
}

export type StatsdClientInstance = Function & {
  increment(key: string, measurement: string): void;
  decrement(key: string, measurement: string): void;
  gauge(key: string, measurement: string, value: any): void;
  timing(key: string, measurement: string, value: any): void;
  set(key: string, measurement: string, value: any): void;
};

const StatsdClient = (function (options: StatsdClientOptions = {}) {
  this.FLUSH_INTERVAL = (options.flushInterval || 10) * 1000;
  this.QUEUE_LIMIT = options.queueLimit || 10;

  this.flushUrl = options.flushUrl;

  this._statsdQueue = [];
  this._beforeUnloadQueue = {};

  this._flushTimeout = null;
  if (!isBrowser) return;
  if (!options.stopped) this._startIntervalFlush();

  this._registerBeforeUnload();
} as any) as StatsdClientConstructor;

(function () {
  // Returns data ready to be sent to server.
  this._preparedData = function () {
    const data = new FormData();
    data.append('data', JSON.stringify(this._statsdQueue));
    return data;
  };

  // Flushes the queue.
  this._flush = function () {
    beacon(this.flushUrl, this._preparedData());
    this._statsdQueue = [];
  };

  // Flushes if we have to
  this._flushIfNecessary = function () {
    if (this._statsdQueue.length > 0) {
      this._flush();
    }
  };

  // runs any beforeUnload based stats before flushing
  this._flushBeforeOnload = function () {
    for (const action in this._beforeUnloadQueue) {
      if (this._beforeUnloadQueue[action]) {
        this._beforeUnloadQueue[action]();
      }
    }
    this._flushIfNecessary();
  };

  // Adds the stat to the queue with the proper format.
  this._record = function (
    key: string,
    measurement: string,
    action: any,
    value: any
  ) {
    const stat: StatsdStat = {
      name: key,
      payload: {
        measurement,
        action,
      },
    };

    if (value !== null) {
      stat.payload.value = value;
    }

    this._statsdQueue.push(stat);

    // Check for a overflow flush asynchronously.
    setTimeout(this._flushIfOverflow.bind(this), 0);
  };

  // Flush if we have more things stored than we are willing to have
  this._flushIfOverflow = function () {
    if (this._statsdQueue.length >= this.QUEUE_LIMIT) {
      // Boolean if we need to restart interval or not.
      let restart = false;
      if (this._flushTimeout !== null) {
        this._stopIntervalFlush();
        restart = true;
      }
      this._flush();
      if (restart) this._startIntervalFlush();
    }
  };

  // Clears the interval timeout.
  this._stopIntervalFlush = function () {
    clearTimeout(this._flushTimeout);
    this._flushTimeout = null;
  };

  // Checks if a flush is necessary and interval recurses.
  this._startIntervalFlush = function () {
    if (document.readyState === 'complete') this._flushIfNecessary();
    this._flushTimeout = setTimeout(
      this._startIntervalFlush.bind(this),
      this.FLUSH_INTERVAL
    );
  };

  // Registers a flush to occur when the window is going down (if we have anything).
  this._registerBeforeUnload = function () {
    if (isBrowser) {
      onPageHide(this._flushBeforeOnload.bind(this));
    }
  };

  /**
   * The public statsd methods.
   */
  this.increment = function (key: string, measurement: string) {
    this._record(key, measurement, 'increment', null);
  };

  this.decrement = function (key: string, measurement: string) {
    this._record(key, measurement, 'decrement', null);
  };

  this.gauge = function (key: string, measurement: string, value: any) {
    this._record(key, measurement, 'gauge', value);
  };

  this.timing = function (key: string, measurement: string, value: any) {
    this._record(key, measurement, 'timing', value);
  };

  this.set = function (key: string, measurement: string, value: any) {
    this._record(key, measurement, 'set', value);
  };

  /**
   * RegisterUnloadAction
   * registers a callback that will be called before unloading the page
   *
   * @param key - id for this action
   * @param cb - callback to be called before unload
   */
  this.registerUnloadAction = function (key: string, cb: () => void) {
    // overwrite existing queue items
    this._beforeUnloadQueue[key] = cb;
  };
}.call(StatsdClient.prototype));

export const statsd = new StatsdClient({
  flushUrl: ENDPOINT,
});
