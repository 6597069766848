import { createAction } from 'redux-actions';

import {
  DeregisterPayload,
  FetchSuccessPayload,
  MarkReadPayload,
  RegisterPayload,
} from './types';

const PREFIX = 'notifications';

export const notificationsAreaRegistered = createAction<RegisterPayload>(
  `${PREFIX}/AREA_REGISTERED`
);
export const notificationsAreaDeregistered = createAction<DeregisterPayload>(
  `${PREFIX}/AREA_DEREGISTERED`
);
export const pollNotificationsStarted = createAction<void>(
  `${PREFIX}/POLL_STARTED`
);

export const pollNotificationsStopped = createAction<void>(
  `${PREFIX}/POLL_STOPPED`
);

export const pollNotificationsRequested = createAction<void>(
  `${PREFIX}/POLL_REQUESTED`
);

export const fetchNotificationsSucceeded = createAction<FetchSuccessPayload>(
  `${PREFIX}/FETCH_SUCCEEDED`
);
export const notificationsDeleted = createAction<MarkReadPayload>(
  `${PREFIX}/DELETED`
);

export const notificationsMarkedRead = createAction<MarkReadPayload>(
  `${PREFIX}/MARKED_READ`
);
