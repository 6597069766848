import { ccdata } from '~/libs/ccdata';
import { RawCurrentUser } from '~/typings/user';

/**
 * @deprecated Use Redux instead!
 * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
 * mostly around storing _never-changing_ global API configurations and authentication keys.
 * But for the most part you should really be using Redux for shared data.
 * Talk to Web Platform if you want to use this. :)
 */
export const userAttributes = {
  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isAdmin() {
    return this._hasRole('admin');
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isAdvisor() {
    return this._hasRole('advisor');
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isCustomerService() {
    return this._hasRole('customer_service');
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isAuthor() {
    return this._hasRole('author');
  },

  _hasRole(role: string) {
    const roles = this.get('roles') || [];
    return roles.indexOf(role) >= 0;
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isAnon() {
    return !ccdata.has('current_user');
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  isPro() {
    return this.get('pro', false);
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  id() {
    return this.get('_id', 'anon');
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  authenticationToken() {
    const user = this.isAnon()
      ? ccdata.get('anonymous_user')
      : ccdata.get('current_user');
    return user?.authentication_token || '';
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  get<Key extends keyof RawCurrentUser>(
    key: Key,
    def: RawCurrentUser[Key] | undefined = undefined
  ) {
    if (__DEV__ && key === 'authentication_token') {
      throw new Error('use userAttributes.authenticationToken()');
    }
    const value = ((ccdata.get('current_user') || {}) as any)[key];

    return value === undefined ? def : value;
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  hasCreatorMode() {
    return (
      userAttributes.isAdmin() ||
      userAttributes.isAdvisor() ||
      userAttributes.isAuthor()
    );
  },

  /**
   * @deprecated Use Redux instead!
   * Ok, technically this isn't "deprecated": there are some real use cases for userAttributes,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  payments<Key extends keyof CCDataPayments>(
    key: Key,
    def?: CCDataPayments[Key]
  ) {
    return ccdata.get('payments', {} as any)![key] || def;
  },
};
