import request from 'superagent';

import { logger } from '~/libs/logging/logger';
import { statsd } from '~/libs/statsd';
import { csrf, userAuth } from '~/libs/superagent-auth';
import { UserActivity } from '~/libs/UserActivity';

export const poll = {
  start(requestFrequency: number) {
    const currentVersion = window.ASSETS_VERSION;
    if (__SSR__) return;
    if (!currentVersion) return;

    let userActive = true;
    // called every 30s as long
    // as the user is active
    UserActivity.on('active', () => {
      userActive = true;
    });

    const requestAssetsVersion = async () => {
      const result = await request
        .get('/assets_version')
        .use(csrf)
        .set(
          'Cache-Control',
          'no-cache,no-store,must-revalidate,max-age=-1,private'
        )
        .use(userAuth);

      const newVersion = result.body.value;
      if (!newVersion.match('assets-v')) return;

      if (currentVersion !== newVersion) {
        statsd.increment('mandatory_assets_version.refresh', 'client_refresh');
        window.location.assign(window.location.href);
      }

      userActive = false;
    };

    const tryRequestAssetsVersion = () => {
      // Inactive users try ten times less frequently to help reduce server load
      const nextAttemptDelay = requestFrequency * (userActive ? 1 : 10);

      requestAssetsVersion()
        .catch((error) => logger.error(error))
        .finally(() => setTimeout(tryRequestAssetsVersion, nextAttemptDelay));
    };

    setTimeout(tryRequestAssetsVersion, requestFrequency);
  },
};
