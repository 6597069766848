import { differenceInDays } from 'date-fns';
import { createSelector } from 'reselect';

import * as constants from '~/state/constants';
import {
  selectLocationParams,
  selectLocationQuery,
  selectSafeRedirectUrl,
} from '~/state/location/selectors';
import { SiteState } from '~/state/types';
import { Currency } from '~/typings/payment';

import { BillingSubscription, PlanTaxResult } from './types';

export const selectPaymentState = (state: SiteState) => state.payment;
export const selectAvailablePlans = (state: SiteState) =>
  state.payment.availablePlans;
export const selectCurrentPlan = (state: SiteState) =>
  state.payment.currentPlan;
export const selectSelectedPlanId = (state: SiteState) =>
  state.payment.isBusiness ? 'businessPaidPlan' : state.payment.selectedPlanId;
export const selectExactSelectedPlanId = (state: SiteState) =>
  state.payment.selectedPlanId;

// ts-prune-ignore-next
export const selectSubscriptionState = (state: SiteState) =>
  state.payment.subscription;

export const selectFirstSubscription = (state: SiteState) =>
  state.payment.subscriptions?.[0];

export const selectBillingZipCode = createSelector(
  selectFirstSubscription,
  (subscription) => subscription?.sourceDetails?.zip
);
export const selectBillingCountryCode = createSelector(
  selectFirstSubscription,
  (subscription) => subscription?.sourceDetails?.countryCode
);

export const selectTrialPricing = (state: SiteState) =>
  state.payment.trialPricing;
export const selectPathUpsellContentfulData = (state: SiteState) =>
  state.payment.pathUpsellContentfulData;

export const selectIsBusinessCheckout = (state: SiteState) =>
  state.payment.isBusiness;

export const selectBusinessPaidPlanCurrency = (state: SiteState): Currency =>
  state.payment.businessPaidPlan?.currency || constants.DEFAULT_CURRENCY;

export const selectCurrency = createSelector(
  selectAvailablePlans,
  selectIsBusinessCheckout,
  selectBusinessPaidPlanCurrency,
  (
    availablePlans = {},
    isBusiness,
    selectBusinessPaidPlanCurrency
  ): Currency => {
    if (isBusiness) {
      return selectBusinessPaidPlanCurrency;
    }
    const planKeys = Object.keys(availablePlans);
    return availablePlans[planKeys[0]]?.currency || constants.DEFAULT_CURRENCY;
  }
);

export const selectStudentVerification = (state: SiteState) =>
  state.payment.studentVerification;

export const selectIsStudentPlan = createSelector(
  selectAvailablePlans,
  selectSelectedPlanId,
  (availablePlans = {}, selectedPlanId) => {
    return !!Object.values(availablePlans).find(
      (plan) => plan.plan_id === selectedPlanId
    )?.student_plan;
  }
);

export const selectBusinessPaidPlan = (state: SiteState) =>
  state.payment.businessPaidPlan;

export const selectSelectedPlan = createSelector(
  selectAvailablePlans,
  selectSelectedPlanId,
  (availablePlans = {}, selectedPlanId) => {
    return availablePlans[selectedPlanId];
  }
);

export const selectIsTrialPlan = createSelector(
  selectSelectedPlan,
  selectLocationParams,
  selectIsBusinessCheckout,
  selectBusinessPaidPlan,
  (selectedPlan, params, isBusiness, paidPlan) => {
    if (isBusiness) {
      return (
        params?.transactionType === 'create_trial' ||
        paidPlan?.is_trial_with_payment_info
      );
    }
    return selectedPlan?.is_trial_plan;
  }
);

export const selectSelectedCurrencyCode = createSelector(
  selectSelectedPlan,
  (selectedPlan) => selectedPlan?.currency ?? constants.DEFAULT_CURRENCY
);

export const selectPurchaseOptions = createSelector(
  selectSelectedPlanId,
  selectSelectedPlan,
  selectSelectedCurrencyCode,
  selectSafeRedirectUrl,
  (planId, plan, currency, redirectUrl) => ({
    planId,
    plan,
    currency,
    redirectUrl,
  })
);

export const selectHasTaxError = ({ payment: { tax } }: SiteState) =>
  !!tax?.error;

export const selectTaxAmount = ({
  payment: { tax },
}: Pick<SiteState, 'payment'>) => {
  if (!(tax && 'amount' in tax)) return undefined;
  return tax.amount ? tax.amount * 100 : 0;
};

export const selectTax = createSelector(
  selectTaxAmount,
  selectHasTaxError,
  (amount, error): PlanTaxResult => ({ amount, error })
);

export const selectBulkSavings = createSelector(selectSelectedPlan, (plan) => {
  return (
    parseFloat((plan?.formatted_savings || '').replace(/,/g, '') || '0') * 100
  );
});

export const selectProPrice = createSelector(
  selectSelectedPlan,
  selectIsBusinessCheckout,
  selectBusinessPaidPlan,
  (consumerPlan, isBusiness, businessPlan) => {
    const plan = isBusiness ? businessPlan : consumerPlan;
    if (!plan) return 0;
    const priceStr =
      plan.price_without_savings ||
      plan.price?.toString() ||
      plan.formatted_price;
    const safePrice = parseInt(priceStr.replace(/[\,|\.]/g, ''), 10);
    return safePrice;
  }
);

export const selectCoupon = (state: SiteState) => state.payment.coupon;

export const selectCouponStatus = (state: SiteState) =>
  state.payment.couponStatus;

export const selectCouponError = (state: SiteState) =>
  state.payment.couponError;

export const selectCouponAmount = createSelector(
  selectProPrice,
  selectBulkSavings,
  selectCoupon,
  (price, savings, coupon) => {
    if (!price || !coupon || Object.keys(coupon).length === 0) return 0;

    return coupon.discount_percent
      ? Math.floor(((price - savings) * coupon.discount_percent) / 100)
      : coupon.amount_in_cents;
  }
);

export const selectInitialDiscountCode = createSelector(
  selectLocationQuery,
  selectCoupon,
  (query, coupon) =>
    coupon?.code || (query.discountCode as string | undefined) || ''
);

export const selectSubtotal = createSelector(
  selectProPrice,
  selectBulkSavings,
  selectCouponAmount,
  (price, bulkSavings = 0, couponAmount = 0) => {
    if (!price) {
      return 0;
    }

    const unsafeAmount = price - bulkSavings - couponAmount;
    return unsafeAmount < 0 ? 0 : unsafeAmount;
  }
);

export const selectTotal = createSelector(
  selectSubtotal,
  selectTaxAmount,
  (subtotal, tax = 0) => subtotal + tax
);

export const selectPaypalAuth = (state: SiteState) => state.payment.paypalAuth;
export const selectRecurlyKey = (state: SiteState) => state.payment.recurlyKey;
export const selectPaytmCheckoutScript = (state: SiteState) =>
  state.payment.paytmCheckoutScript;
export const selectPaypalLoading = (state: SiteState) => {
  return (
    state.payment.status === constants.LOADING &&
    state.payment.payMethod === constants.PAYPAL
  );
};

export const selectCreditCardLoading = (state: SiteState) => {
  return (
    state.payment.status === constants.LOADING &&
    state.payment.payMethod === constants.CREDIT_CARD
  );
};

export const selectFormCountryCode = (state: SiteState) =>
  state.payment.form?.countryCode;

export const selectFormFirstName = (state: SiteState) =>
  state.payment.form?.firstName;

export const selectFormLastName = (state: SiteState) =>
  state.payment.form?.lastName;

export const selectPaymentCountryCode = createSelector(
  selectFormCountryCode,
  (countryCode) => countryCode
);

export const selectZipCode = (state: SiteState) => state.payment.form?.zip;

export const selectPostalCode = createSelector(selectZipCode, (zip) => zip);

export const selectBusinessPaidPlanSeats = (state: SiteState) =>
  state.payment.businessPaidPlan?.plan_num_seats || 0;

export const selectBusinessCheckoutTab = (state: SiteState) =>
  state.payment.currentTab!;

export const selectIsBusinessUpsell = (state: SiteState) =>
  state.payment.isBusinessUpsell;

export const selectIsPaymentSuccessful = (state: SiteState) =>
  state.payment.isPaymentSuccessful;

export const selectBusinessPreviewUpsellQuantity = createSelector(
  selectIsBusinessUpsell,
  (state: SiteState) => state.payment.previewSubscriptionFields,
  (isUpsell, previewSubscriptionFields) =>
    isUpsell ? previewSubscriptionFields!.quantity : 0
);

export const selectIsEditingBusinessQuantity = (state: SiteState) =>
  state.payment.isEditingBusinessQuantity || false;

export const selectIsBusinessUpsellValid = createSelector(
  selectIsBusinessUpsell,
  selectBusinessPreviewUpsellQuantity,
  selectBusinessPaidPlan,
  (isUpsell, previewQuantity, businessPaidPlan) => {
    // check that, if we're upselling, the user has added at least one seat to their plan.

    if (isUpsell && businessPaidPlan) {
      return previewQuantity > businessPaidPlan.plan_num_seats!;
    }

    return true;
  }
);

// Selector to check if we should disable the form submit button on business checkout.
// If the user hasn't added any seats or otherwise has ended up in a situation where they
// are going to be charged 0 money, return false.
export const selectIsBusinessFormValid = createSelector(
  selectIsBusinessUpsellValid,
  selectTotal,
  selectIsPaymentSuccessful,
  selectIsEditingBusinessQuantity,
  (isUpsellValid, total, isPaymentSuccessful, isEditingBusinessQuantity) =>
    isUpsellValid &&
    total > 0 &&
    !isPaymentSuccessful &&
    !isEditingBusinessQuantity
);

export const selectBusinessPreviewStatus = (state: SiteState) =>
  state.payment.businessPreviewStatus;

export const selectBusinessPreviewQuantity = (state: SiteState) =>
  state.payment.previewSubscriptionFields?.quantity;

export const selectBusinessPreviewCurrency = (state: SiteState) =>
  state.payment.previewSubscriptionFields?.currency;

// Returns the number of seats being purchased in this session,
// the preview quantity in all cases except upsell, where we take the difference
// between the current and preview state.
export const selectBusinessSeatsBoughtThisSession = createSelector(
  selectIsBusinessUpsell,
  selectBusinessPreviewQuantity,
  selectBusinessPaidPlanSeats,
  (isUpsell, previewQuantity, businessPaidPlanSeats) => {
    if (typeof previewQuantity === undefined) {
      return 0;
    }
    if (isUpsell) {
      return previewQuantity! - businessPaidPlanSeats;
    }
    return previewQuantity!;
  }
);

export const selectBusinessCurrentTabZipCode = createSelector(
  selectZipCode,
  (creditCardZipcode) => {
    return creditCardZipcode;
  }
);

export const selectPayMethodsSupported = (state: SiteState) =>
  state.payment.availablePayMethods;

export const selectBusinessPayMethodsSupported = (state: SiteState) =>
  state.payment.availableBusinessPaymentMethods;

export const selectIsBusinessTaxExempt = (state: SiteState) =>
  state.payment.isBusinessTaxExempt ?? false;

export const selectBusinessPaidPlanPrice = (state: SiteState) =>
  state.payment.businessPaidPlan?.price || 0;

export const selectUserTrialLength = createSelector(
  selectFirstSubscription,
  (subscriptionState: BillingSubscription | undefined) => {
    if (subscriptionState) {
      const { periodEndDt, lastPaymentDate } = subscriptionState;
      if (periodEndDt && lastPaymentDate) {
        const days = differenceInDays(
          /**
           * lastPaymentDate matches last_invoice_created_at & current_period_started_at
           * on the BE, so its safe to assume it === a "start date"
           */
          new Date(periodEndDt),
          new Date(lastPaymentDate)
        );
        return days;
      }
    }
    return 0;
  }
);

export const selectHasUserExtendedTrial = createSelector(
  selectUserTrialLength,
  (trialLength) => trialLength > 7
);

export const selectUserSubscriptionHasActiveTrial = createSelector(
  selectFirstSubscription,
  (subscriptionState: BillingSubscription | undefined) => {
    return Boolean(subscriptionState?.trialActive);
  }
);

export const selectPaytmForm = (state: SiteState) => state.payment.paytmForm;

export const selectIsInMoneybackGuaranteePeriod = createSelector(
  selectFirstSubscription,
  (subscriptionState) => {
    const today = new Date();
    const { lastPaymentDate } = subscriptionState;
    return (
      !!lastPaymentDate &&
      differenceInDays(today, new Date(lastPaymentDate)) <= 15
    );
  }
);
