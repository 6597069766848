import { SiteState } from '~/state/types';
import { ProgressSummaryContainerType } from '~/typings/entities';

interface ContainerLookup {
  type: ProgressSummaryContainerType;
  id: string;
}

const selectProgresses = (state: SiteState) =>
  state.entities.containerProgresses;

export const selectContainerProgress = (
  state: SiteState,
  containerLookup: ContainerLookup
) => selectProgresses(state)[containerLookup.type][containerLookup.id];

export const selectModuleProgress = (state: SiteState, id: string) =>
  selectContainerProgress(state, { type: 'modules', id });

export const selectPathProgress = (state: SiteState, id: string) =>
  selectContainerProgress(state, { type: 'paths', id });

export const selectTrackProgress = (state: SiteState, id: string) =>
  selectContainerProgress(state, { type: 'tracks', id });
