import { handleActions } from 'redux-actions';

const defaultBrandedBanner = {
  content: '',
  backgroundColor: 'navy',
  id: '',
};

// This data is static and only sent on the initial page render
export const brandedBanner = handleActions({}, { ...defaultBrandedBanner });
