// Adapted react_ujs module - pass in an object of components,
// and it handles binding them to pre-rendered DOM elements
import { loadableReady } from '@loadable/component';
import React from 'react';
import ReactDOM from 'react-dom';

import { logger } from './logging/logger';

const CLASS_NAME_ATTR = 'data-react-class';
const PROPS_ID_SELECTOR = '_INITIAL_REACT_PROPS_';

/**
 * getReactDOMNodes
 * gets all DOM Elements with the class CLASS_NAME_ATTR
 * @return React element container elements
 */
const getReactDOMNodes = () => [
  ...document.querySelectorAll(`[${CLASS_NAME_ATTR}]`),
];

let initialReactProps: any;

const getInitialReactProps = () => {
  if (initialReactProps) return initialReactProps;
  try {
    return JSON.parse(
      document.getElementById(PROPS_ID_SELECTOR)?.textContent?.trim() || ''
    );
  } catch (err) {
    logger.error(err);
    return {};
  }
};

export const initializeReactBinder = async (
  components: Record<string, React.ComponentType<React.PropsWithChildren<any>>>,
  asyncComponents: Record<
    string,
    () => Promise<React.ComponentType<React.PropsWithChildren<any>>>
  > = {}
) => {
  const mountReactComponentToNode = async (node: HTMLElement) => {
    const className = node.getAttribute(CLASS_NAME_ATTR)!;

    let Component = components[className];
    const asyncComponent = asyncComponents[className];
    if (asyncComponent) {
      Component = await asyncComponent();
    }

    if (Component) {
      const props = getInitialReactProps();

      // Only use hydrate when using SSR
      if (node.innerHTML) {
        ReactDOM.hydrate(<Component {...props} />, node);
      } else {
        ReactDOM.render(<Component {...props} />, node);
      }
    }
  };

  // Temporary: Remove when local dev includes server side rendering
  if (!__DEV__) {
    // Wait for scripts loaded async on the initial load of this page server-side
    await loadableReady();
  }

  const nodes = getReactDOMNodes();

  return Promise.all(nodes.map(mountReactComponentToNode));
};
