import { isEmpty } from 'lodash';
import { Action } from 'redux-actions';
import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import request from 'superagent';

import { logger } from '~/libs/logging/logger';
import { jwtAuth } from '~/libs/superagent-auth';
import { selectIsLoggedIn } from '~/state/currentUser/selectors';
import { contentItemProgressSynced } from '~/state/entities/actions';
import { practiceProgressSynced } from '~/state/practice/actions';

import { selectNotificationsServiceBaseUrl } from '../apiConfig/selectors';
import { pageVisibilityChange } from '../page-visibility/actions';
import {
  fetchNotificationsSucceeded,
  notificationsAreaDeregistered,
  notificationsAreaRegistered,
  notificationsDeleted,
  notificationsMarkedRead,
  pollNotificationsRequested,
  pollNotificationsStarted,
  pollNotificationsStopped,
} from './actions';
import {
  selectShouldPollNotifications,
  selectShouldStopPollNotifications,
} from './selectors';
import { DeletePayload, FetchSuccessPayload, MarkReadPayload } from './types';

const POLL_INTERVAL = 5 * 60000;

function* fetchNotifications() {
  const baseUrl: string = yield select(selectNotificationsServiceBaseUrl);
  try {
    const res: request.Response = yield request
      .get(`${baseUrl}/notifications?target=web`)
      .use(jwtAuth);
    return res.body?.events ?? [];
  } catch (err) {
    logger.info(`Failed to fetch notifications: ${err}`, 'javascript_errors');
    return [];
  }
}

export interface DeleteOnServerData {
  ids: string[];
}

export interface MarkReadOnServerData {
  ids: string[];
}

export function* deleteOnServer({ ids }: DeleteOnServerData) {
  if (isEmpty(ids)) {
    return null;
  }

  const baseUrl: string = yield select(selectNotificationsServiceBaseUrl);
  const url = `${baseUrl}/notifications?ids=${ids.join(',')}`;
  return request.delete(url).use(jwtAuth).then();
}

export function* markReadOnServer({ ids }: MarkReadOnServerData) {
  if (isEmpty(ids)) {
    return null;
  }
  const baseUrl: string = yield select(selectNotificationsServiceBaseUrl);
  const url = `${baseUrl}/notifications?ids=${ids.join(',')}`;
  return request.patch(url).use(jwtAuth).send({ status: 0 }).then();
}

export function* handleRegisterNotificationArea() {
  yield call(pollNotificationsStartConditionally);
}

export function* handleDeregisterNotificationArea() {
  const shouldStopPoll: boolean = yield select(
    selectShouldStopPollNotifications
  );
  if (shouldStopPoll) {
    yield put(pollNotificationsStopped());
  }
}

export function* handleNotificationsDeleted({
  payload: ids,
}: Action<DeletePayload>) {
  if (ids?.length) {
    yield call(deleteOnServer, { ids });
  }
}

export function* handleNotificationsMarkedRead({
  payload: ids,
}: Action<MarkReadPayload>) {
  if (ids?.length) {
    yield call(markReadOnServer, { ids });
  }
}

export function* handleFetchNotifications() {
  const notifications: FetchSuccessPayload = yield call(fetchNotifications);

  if (notifications.length) {
    yield put(fetchNotificationsSucceeded(notifications));
  }
}

export function* handleFetchNotificationsDelayed() {
  yield new Promise<void>((resolve) => setTimeout(() => resolve(), 3000));
  yield call(handleFetchNotifications);
}

export function* pollNotificationsStartConditionally() {
  const isLoggedIn: boolean = yield select(selectIsLoggedIn);
  const shouldPoll: boolean = yield select(selectShouldPollNotifications);

  if (isLoggedIn && shouldPoll) {
    yield put(pollNotificationsStarted());
  }
}

export function* pollNotifications(): Generator {
  yield put(pollNotificationsRequested());

  yield new Promise<void>((resolve) =>
    setTimeout(() => resolve(), POLL_INTERVAL)
  );
  yield call(pollNotifications);
}

export function* watchNotificationPoll() {
  while (true) {
    yield take(pollNotificationsStarted);
    yield race({
      response: call(pollNotifications),
      cancel: take(pollNotificationsStopped),
    });
  }
}

export function* watchPageVisibility() {
  while (true) {
    const { payload: page } = yield take(pageVisibilityChange);
    if (page.hidden) {
      yield put(pollNotificationsStopped());
    } else {
      yield call(pollNotificationsStartConditionally);
    }
  }
}

export function* notificationsSagas() {
  yield takeLatest(
    [contentItemProgressSynced, practiceProgressSynced],
    handleFetchNotificationsDelayed
  );
  yield takeLatest(pollNotificationsRequested, handleFetchNotifications);
  yield takeLatest(notificationsAreaRegistered, handleRegisterNotificationArea);
  yield takeLatest(
    notificationsAreaDeregistered,
    handleDeregisterNotificationArea
  );
  yield takeLatest(notificationsDeleted, handleNotificationsDeleted);
  yield takeLatest(notificationsMarkedRead, handleNotificationsMarkedRead);
  yield all([call(watchNotificationPoll), call(watchPageVisibility)]);
}
