import { genericRequest, handleResponse } from '~/libs/genericRequest';
import { userAttributes } from '~/libs/userAttributes';
import {
  ContainerProgress,
  ContentItemProgress,
  ProgressSummaryContainerType,
} from '~/typings/entities';

// this method requires a CCDATA object on the window from which to grab auth tokens
const supportedTypes = ['tracks', 'paths', 'modules', 'journeys'];

export interface ContainerProgressSummaryRequest {
  contentType?: string;
  contentId?: string;
  userId?: string;
  includeContentItemProgresses?: boolean;
  includeChildSummaries?: boolean;
  logAndSwallowErrors?: boolean;
}

export type ContainerProgressSummaryResponse = Record<
  ProgressSummaryContainerType | 'content_item_progresses' | 'units',
  Record<string, ContainerProgress>
>;

export function containerProgressSummaryRequest({
  contentType,
  contentId,
  userId = userAttributes.id(),
  includeContentItemProgresses = false,
  includeChildSummaries = false,
  logAndSwallowErrors = false,
}: ContainerProgressSummaryRequest = {}) {
  if (!supportedTypes.includes(contentType!)) {
    throw new Error(`Invalid type: ${contentType}`);
  }

  if (!contentId) {
    throw new Error('No content id specified');
  }

  return genericRequest({
    method: 'GET',
    endpoint: `/progress-summaries/v1/${contentType}/${contentId}`,
    query: {
      user_id: userId,
      include_progress_items: includeContentItemProgresses,
      include_child_collections: includeChildSummaries,
    },
  }).then(
    handleResponse<ContainerProgressSummaryResponse>({
      logAndSwallowErrors,
      errorMessage: `Unable to fetch data from progress summary request for type: ${contentType}`,
    })
  );
}

export interface ContentItemProgressSummaryRequest {
  ids?: string[];
  includeContentItemProgresses?: boolean;
  logAndSwallowErrors?: boolean;
}

export function contentItemProgressSummaryRequest({
  ids = [],
  includeContentItemProgresses = true,
  logAndSwallowErrors = false,
}: ContentItemProgressSummaryRequest = {}) {
  if (!ids.length) {
    throw new Error('No content item ids specified');
  }

  return genericRequest({
    method: 'POST',
    endpoint: `/progress-summaries/v1/content-items`,
    data: {
      ids,
      user_id: userAttributes.id(),
      include_progress_items: includeContentItemProgresses,
    },
  }).then<{ content_item_progresses: ContentItemProgress }>(
    handleResponse({
      logAndSwallowErrors,
      errorMessage: `Unable to fetch data from progress summary request for type: content-items`,
    })
  );
}

export const progressSummaryRequest = {
  module(contentId: string, options?: ContainerProgressSummaryRequest) {
    return containerProgressSummaryRequest({
      contentType: 'modules',
      contentId,
      ...options,
    });
  },
  contentItems(ids: string[], options?: ContentItemProgressSummaryRequest) {
    return contentItemProgressSummaryRequest({
      ids,
      ...options,
    });
  },
};
