import { createAction } from 'redux-actions';

/**
 * Note: thunks for payments have been moved to `portal/state/payments/thunks`
 * Because the payments reducer is used globally across the site,
 * this ensures that large payments api libraries
 * do not add to the size of other bundles
 */

export const paymentStart = createAction('PAYMENT_REQUEST_START');
export const paymentError = createAction('PAYMENT_REQUEST_ERROR');
export const paymentSuccess = createAction('PAYMENT_REQUEST_SUCCESS');
export const selectPlan = createAction('SELECT_PLAN');
export const validateCouponStart = createAction('DISCOUNT_CODE_SUBMITTED');
export const validateCouponError = createAction('DISCOUNT_CODE_ERROR');
export const validateCouponSuccess = createAction('DISCOUNT_CODE_VALIDATED');
export const validateCouponReset = createAction('DISCOUNT_CODE_RESET');
export const getTaxStart = createAction('GET_TAX_START');
export const getTaxFailed = createAction('GET_TAX_FAILED');
export const setTax = createAction('SET_TAX');
export const paymentUpdateForm = createAction('PAYMENT_UPDATE_FORM');
export const paymentUpdateBankDebitForm = createAction(
  'UPDATE_BANK_DEBIT_FORM'
);
export const deleteBankAccountType = createAction('DELETE_BANK_ACCOUNT_TYPE');
export const businessPreviewStart = createAction(
  'BUSINESS_PREVIEW_REQUEST_START'
);
export const businessPreviewError = createAction(
  'BUSINESS_PREVIEW_REQUEST_ERROR'
);
export const businessPreviewSuccess = createAction(
  'BUSINESS_PREVIEW_REQUEST_SUCCESS'
);
export const businessPreviewSeatUpdateError = createAction(
  'BUSINESS_PREVIEW_SEAT_TOTAL_REQUEST_ERROR'
);
export const businessPreviewSeatUpdateSuccess = createAction(
  'BUSINESS_PREVIEW_SEAT_UPDATE_REQUEST_SUCCESS'
);
export const setSeatTotal = createAction('SET_SEAT_TOTAL');
export const termsAndPolicyChecked = createAction('TERMS_AND_POLICY_CHECKED');
export const termsAndPolicyUnchecked = createAction(
  'TERMS_AND_POLICY_UNCHECKED'
);
export const updatePaymentCurrency = createAction('UPDATE_CURRENCY');

export const setCurrentTab = createAction('SET_CURRENT_TAB');
export const resetPreviewPrices = createAction('RESET_PREVIEW_PRICES');
export const setEditingBusinessQuantity = createAction(
  'SET_EDITING_BUSINESS_QUANTITY'
);
export const setIsBusinessFormFieldError = createAction(
  'BUSINESS_FORM_FIELD_BLUR_Change'
);
export const setIsZipCodeFilled = createAction('ZIP_CODE_FILLED');

export const updateAvailablePlans = createAction('UPDATE_AVAILABLE_PLANS');

export const paytmPaymentUpdateForm = createAction('PAYTM_PAYMENT_UPDATE_FORM');
