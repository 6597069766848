import { sortBy } from 'lodash';
import { createSelector, Selector } from 'reselect';

import { selectTrackContentItemIds } from '~/state/entities/tracks/selectors';
import { selectReleasedPathIds } from '~/state/releasedContent/selectors';
import { SiteState } from '~/state/types';
import { Path } from '~/typings/entities/path';

// Generic Selectors
export const selectAllPaths = (state: SiteState) =>
  state.entities.paths.byId || {};

export const selectPath = createSelector(
  selectAllPaths,
  (_: SiteState, id: string) => id,
  (paths, id) => paths[id]
);

export const selectPathBySlug = createSelector(
  selectAllPaths,
  (_: SiteState, slug: string) => slug,
  (paths, slug) => Object.values(paths).find((path) => path.slug === slug)
);

export const selectPathContentItemIds = createSelector(
  selectPath,
  (state: SiteState) => state,
  (path, state) => {
    return (
      path?.track_ids.reduce<string[]>(
        (items, trackId) =>
          items.concat(selectTrackContentItemIds(state, trackId)),
        []
      ) ?? []
    );
  }
);

// Other selectors

const selectPathsByGoal = (goal: string) =>
  createSelector(selectAllPaths, (paths) =>
    Object.values(paths).filter((path) => path.goal === goal)
  );

const selectReleasedPaths = (pathsSelector: Selector<SiteState, Path[]>) =>
  createSelector(
    pathsSelector,
    selectReleasedPathIds,
    (paths: Path[], releasedPathIds: string[]) => {
      const releasedPaths = paths.filter((path) =>
        releasedPathIds.includes(path.id)
      );
      return sortBy(releasedPaths, (path) => releasedPathIds.indexOf(path.id));
    }
  );

export const selectAllCareerPaths = selectPathsByGoal('career');
export const selectAllSkillPaths = selectPathsByGoal('skill');

export const selectReleasedCareerPaths = selectReleasedPaths(
  selectAllCareerPaths
);

export const selectReleasedSkillPaths = selectReleasedPaths(
  selectAllSkillPaths
);
