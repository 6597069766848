import { handleActions } from 'redux-actions';

import { CouponStatus } from '~/typings/payment';

import * as constants from '../constants';
import * as actions from './actions';

export const defaultState: any = {
  plans: {},
  umbrellas: {},
  subscriptions: [],
  coupon: {},
  isUSA: null,
  form: {
    countryCode: '',
    firstName: '',
    lastName: '',
    zip: '',
  },
  paytmForm: {
    firstName: '',
    lastName: '',
    pinCode: '',
  },
  bankDebitForm: {
    name_on_account: '',
    routing_number: '',
    account_number: '',
    account_number_confirmation: '',
    account_type: '',
    country: '',
    postal_code: '',
    sort_code: '',
    type: '',
    iban: '',
  },
  subscriptionTermsOfService: '',
  paypalRedirectNotice: '',
};

export const payment = handleActions(
  {
    [`${actions.selectPlan}`]: (state, { payload }) => ({
      ...state,
      selectedPlanId: payload,
      couponStatus: CouponStatus.RESET,
      coupon: null,
      couponError: null,
    }),
    [`${actions.paymentStart}`]: (state, { payload = {} as any }) => ({
      ...state,
      provider: payload.provider,
      status: constants.LOADING,
      error: null,
      errorFields: [],
      payMethod: payload.payMethod,
    }),
    [`${actions.paymentError}`]: (
      state,
      { payload: { message, fields = [] } }
    ) => ({
      ...state,
      status: constants.FAILED,
      error: message,
      errorFields: fields,
      payMethod: undefined,
      flashMessages: [{ level: 'error', text: message }],
    }),
    [`${actions.paymentSuccess}`]: (state, { payload = {} as any }) => {
      const nextState = {
        ...state,
        status: constants.COMPLETED,
        error: null,
        payMethod: undefined,
        isPaymentSuccessful: true,
      };

      if (payload.subscriptions || payload.payment?.subscriptions) {
        nextState.subscriptions =
          payload.subscriptions || payload.payment.subscriptions;
      }
      if (payload.flashMessages) {
        nextState.flashMessages = payload.flashMessages;
      }

      return nextState;
    },
    [`${actions.businessPreviewStart}`]: (state) => ({
      ...state,
      businessPreviewStatus: constants.LOADING,
    }),
    [`${actions.businessPreviewError}`]: (state, { payload: { error } }) => ({
      ...state,
      error,
      businessPreviewStatus: constants.FAILED,
    }),
    [`${actions.businessPreviewSuccess}`]: (
      state,
      { payload: { subscription } }
    ) => {
      const paymentBusinessPaidPlan = state.businessPaidPlan;
      const nextState = {
        ...state,
        businessPreviewStatus: constants.COMPLETED,
        error: null,
        businessPaidPlan: {
          ...paymentBusinessPaidPlan,
          price: subscription.total_amount_in_cents,
        },
      };

      if (subscription) {
        nextState.previewSubscriptionFields = subscription;
      }
      return nextState;
    },
    [`${actions.businessPreviewSeatUpdateError}`]: (
      state,
      { payload: { error } }
    ) => ({
      ...state,
      error,
      businessPreviewStatus: constants.FAILED,
    }),
    [`${actions.businessPreviewSeatUpdateSuccess}`]: (
      state,
      { payload: { subscription } }
    ) => {
      const paymentBusinessPaidPlan = state.businessPaidPlan;
      const nextState = {
        ...state,
        businessPreviewStatus: constants.COMPLETED,
        error: null,
        businessPaidPlan: {
          ...paymentBusinessPaidPlan,
          price: subscription.balance_in_cents,
        },
      };

      if (subscription) {
        nextState.previewSubscriptionFields = subscription;
      }
      return nextState;
    },
    [`${actions.setTax}`]: (state, { payload }) => {
      const { amount } = payload;
      return {
        ...state,
        tax: {
          ...state.tax,
          amount,
        },
      };
    },
    [`${actions.getTaxFailed}`]: (state) => ({
      ...state,
      tax: { error: true },
    }),
    [`${actions.paymentUpdateForm}`]: (state, { payload: formUpdates }) => ({
      ...state,
      form: {
        ...state.form,
        ...formUpdates,
      },
    }),
    [`${actions.paymentUpdateBankDebitForm}`]: (
      state,
      { payload: formUpdates }
    ) => ({
      ...state,
      bankDebitForm: {
        ...state.bankDebitForm,
        ...formUpdates,
      },
    }),
    [`${actions.setSeatTotal}`]: (state, { payload: planNumSeats }) => ({
      ...state,
      businessPaidPlan: {
        ...state.businessPaidPlan,
        plan_num_seats: planNumSeats,
      },
    }),
    [`${actions.resetPreviewPrices}`]: (state, { payload: quantity }) => {
      const paymentBusinessPaidPlan = state.businessPaidPlan;
      return {
        ...state,
        businessPreviewStatus: constants.COMPLETED,
        tax: {
          subtotal: 0,
        },
        businessPaidPlan: {
          ...paymentBusinessPaidPlan,
          price: 0,
          plan_num_seats: quantity,
        },
      };
    },
    [`${actions.validateCouponStart}`]: (state) => ({
      ...state,
      couponStatus: CouponStatus.LOADING,
      couponError: null,
    }),
    [`${actions.validateCouponError}`]: (state, { payload: message }) => ({
      ...state,
      couponStatus: CouponStatus.FAILED,
      coupon: null,
      couponError: message,
    }),
    [`${actions.validateCouponSuccess}`]: (state, { payload: coupon }) => ({
      ...state,
      couponStatus: CouponStatus.COMPLETED,
      coupon,
      couponError: null,
    }),
    [`${actions.validateCouponReset}`]: (state) => ({
      ...state,
      couponStatus: CouponStatus.RESET,
      coupon: null,
      couponError: null,
    }),

    [`${actions.deleteBankAccountType}`]: (state, { payload: formUpdate }) => {
      const nextState = {
        ...state,
      };
      if (formUpdate.usAccountType) {
        delete state.bankDebitForm?.account_type;
        delete state.bankDebitForm?.state;
      }
      if (formUpdate.UKandAUBankType) {
        delete state.bankDebitForm?.type;
      }
      return nextState;
    },

    [`${actions.termsAndPolicyChecked}`]: (state, { payload: tab }) => ({
      ...state,
      acceptedTermsAndPolicy: {
        ...state.acceptedTermsAndPolicy,
        [tab]: true,
      },
    }),
    [`${actions.termsAndPolicyUnchecked}`]: (state, { payload: tab }) => ({
      ...state,
      acceptedTermsAndPolicy: {
        ...state.acceptedTermsAndPolicy,
        [tab]: false,
      },
    }),
    [`${actions.setCurrentTab}`]: (state, { payload: currentTab }) => ({
      ...state,
      acceptedTermsAndPolicy: {
        ...state.acceptedTermsAndPolicy,
        currentTab,
      },
      currentTab,
    }),
    [`${actions.updatePaymentCurrency}`]: (
      state,
      { payload: { currency } }
    ) => ({
      ...state,
      businessPaidPlan: {
        ...state.businessPaidPlan,
        currency,
      },
    }),
    [`${actions.setEditingBusinessQuantity}`]: (
      state,
      { payload: isEditingBusinessQuantity }
    ) => ({
      ...state,
      isEditingBusinessQuantity,
    }),
    [`${actions.setIsBusinessFormFieldError}`]: (
      state,
      { payload: isBusinessFormFieldError }
    ) => ({
      ...state,
      isBusinessFormFieldError,
    }),
    [`${actions.setIsZipCodeFilled}`]: (
      state,
      { payload: isZipCodeFilled }
    ) => ({
      ...state,
      isZipCodeFilled,
    }),
    [`${actions.updateAvailablePlans}`]: (
      state,
      { payload: availablePlans }
    ) => ({
      ...state,
      availablePlans,
    }),
    [`${actions.paytmPaymentUpdateForm}`]: (
      state,
      { payload: formUpdates }
    ) => ({
      ...state,
      paytmForm: {
        ...state.paytmForm,
        ...formUpdates,
      },
    }),
  },
  defaultState
);
