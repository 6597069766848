import {
  createTracker,
  EventDataTypes,
  TrackingOptions,
  UserClickData,
  UserImpressionData,
  UserVisitData,
} from '@codecademy/tracking';
import { isEqual } from 'lodash';

import { logger } from '~/libs/logging/logger';

const API_BASE_URL = __SSR__ ? '' : window?.location?.origin;
const source_codebase = 'monolith';
const tracker = createTracker({
  apiBaseUrl: API_BASE_URL,
  verbose: __DEV__,
});

export function track<
  Category extends keyof EventDataTypes,
  Event extends string & keyof EventDataTypes[Category],
  Data extends EventDataTypes[Category][Event]
>(
  category: Category,
  event: Event,
  userData: Data,
  options: TrackingOptions = {}
) {
  if (__SSR__) return;

  tracker.event(category, event, { ...userData, source_codebase }, options);
}

export const trackEvent: typeof tracker.event = (category, event, data) =>
  tracker.event(category, event, { ...data, source_codebase });

export const trackUserClick = (data: UserClickData) => {
  track('user', 'click', data);
};
export const trackUserImpression = (data: UserImpressionData) => {
  track('user', 'impression', data);
};

let previousData: UserVisitData;
export const trackUserVisit = (data: UserVisitData) => {
  if (isEqual(data, previousData)) {
    logger.warn(
      `Consecutive trackUserVisit events fired with the same data: ${data.page_name}`
    );
    return;
  }
  previousData = data;
  track('user', 'visit', data);
};

export const { pushDataLayerEvent } = tracker;
