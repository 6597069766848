import { initializeTrackingIntegrations } from '@codecademy/tracking';
import { TrackingWindow } from '@codecademy/tracking/dist/integrations/types';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import { ccdata } from '~/libs/ccdata';
import { selectCurrentUser } from '~/state/currentUser/selectors';

export const useTrackingIntegration = () => {
  const currentUser = useSelector(selectCurrentUser);

  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      initializeTrackingIntegrations({
        environment: ccdata.get('env'),
        scope: window as TrackingWindow,
        optedOutExternalTracking: currentUser.optedOutExternalTracking || false,
      });
    }
  });
};
