export function sessionId() {
  let sessionId: string | null = null;
  if (window.sessionStorage?.getItem) {
    const cachedSessionId = window.sessionStorage.getItem('logger_session_id');
    if (cachedSessionId !== null) {
      sessionId = cachedSessionId;
    }
  }
  if (sessionId === null) {
    sessionId = 'xxxxxx'.replace(/x/g, () =>
      ((Math.random() * 16) | 0).toString(16)
    );
  }
  if (window.sessionStorage?.setItem) {
    window.sessionStorage.setItem('logger_session_id', sessionId);
  }
  return sessionId;
}
