import { handleActions } from 'redux-actions';

import { addAlert, closeAlert } from './actions';
import { AlertsPayload, AlertsState } from './types';

export const alerts = handleActions<AlertsState, AlertsPayload>(
  {
    [`${addAlert}`]: (
      state,
      { payload }: Required<ReturnType<typeof addAlert>>
    ) => {
      return [...state, payload];
    },
    [`${closeAlert}`]: (
      state,
      { payload }: Required<ReturnType<typeof closeAlert>>
    ) => state.filter((serverAlert) => serverAlert.message !== payload.message),
  },
  []
);
