import { capitalize } from 'lodash';

import { ById } from '~/typings/entities';

export interface AddEntityOptions {
  idKey?: 'id' | 'uuid' | 'slug';
  alternateKey?: string;
}

export function addEntities(
  state: Record<string, any>,
  items: any,
  options: AddEntityOptions = {}
) {
  const idKey = options.idKey || 'id';
  const lookupKey = `by${capitalize(idKey)}`;

  let entities;

  if (Array.isArray(items)) {
    entities = {};
    items.forEach((item) => {
      entities[item[idKey]] = item;
    });
  } else {
    entities = items;
  }

  const newPrimaryLookup = {
    ...state[lookupKey],
    ...entities,
  };

  if (options.alternateKey) {
    const { alternateKey } = options;
    const alternateLookupKey = `idsBy${capitalize(alternateKey)}`;
    const alternateKeyedIds: Record<string, any> = {};
    Object.keys(newPrimaryLookup).forEach((key) => {
      alternateKeyedIds[newPrimaryLookup[key][alternateKey]] = key;
    });

    return {
      ...state,
      [lookupKey]: newPrimaryLookup,
      [alternateLookupKey]: {
        ...state[alternateLookupKey],
        ...alternateKeyedIds,
      },
    };
  }

  return {
    ...state,
    [lookupKey]: newPrimaryLookup,
  };
}

export function updateObjectById<Entity>(
  state: ById<Entity>,
  id: string,
  obj: Entity
) {
  return {
    ...state,
    byId: {
      ...state.byId,
      [id]: {
        ...state.byId[id],
        ...obj,
      },
    },
  };
}
