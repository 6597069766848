const slowEffectiveTypes = new Set<string | undefined>(['slow-2g', '2g', '3g']);

export function isKnownSlowNetwork() {
  try {
    const connection: NetworkInformation | undefined =
      (navigator as any).connection ??
      (navigator as any).mozConnection ??
      (navigator as any).webkitConnection;

    return slowEffectiveTypes.has(connection?.effectiveType);
  } catch {
    return false;
  }
}
