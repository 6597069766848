import { EventEmitter } from 'events';

import { Riveted } from './riveted';

/**
 * UserActivity
 *
 * Provides an event heartbeat when the user is active
 *
 * This class is not configurable and should be used as a singleton instance
 *
 * Usage:
 *
 * UserActivity.on('activity', fn);
 * UserActivity.removeListenr('activity', fn);
 */
class UserActivityEmitter extends EventEmitter {
  riveted: typeof Riveted;
}

export const UserActivity = new UserActivityEmitter();

Riveted.init({
  idleTimeout: 60,
  reportInterval: 30,
  userTimingHandler: (...args) => UserActivity.emit('timing', ...args),
  eventHandler: () => {
    UserActivity.emit('active');
  },
});

UserActivity.riveted = Riveted;
