export type PaymentPlans = Record<string, PaymentPlan>;

export type PlanType = 'basic' | 'pro' | 'pro-silver' | 'pro-gold';

export interface PaymentPlan {
  currency: Currency;
  display_name?: string;
  formatted_monthly_price: string;
  formatted_price: string;
  formatted_savings_six_months?: string;
  currency_symbol?: CurrencySymbol;
  monthly_price_without_savings?: string;
  formatted_savings?: string;
  period_number: number;
  period: 'month' | 'six_months' | 'year';
  plan_id: string;
  price_without_savings: string;
  price: number;
  title: string;
  is_trial_plan?: boolean;
  student_plan?: boolean;
  plan_type: PlanType;
  included_tax_rate?: number;
  upgradable?: boolean;
}

export enum Currency {
  USD = 'USD',
  EUR = 'EUR',
  CAD = 'CAD',
  GBP = 'GBP',
  AUD = 'AUD',
  INR = 'INR',
  SEK = 'SEK',
  NOK = 'NOK',
  PLN = 'PLN',
  DKK = 'DKK',
  BRL = 'BRL',
  BGN = 'BGN',
  CHF = 'CHF',
  CZK = 'CZK',
  HRK = 'HRK',
  HUF = 'HUF',
  MXN = 'MXN',
  RON = 'RON',
}

export enum CurrencySymbol {
  DOLLAR = '$',
  POUND = '£',
  EURO = '€',
  RUPEE = '₹',
  KRONA = 'kr',
  KRONE = 'kr',
  ZLOTY = 'zł',
  REAL = 'R$',
  LEV = 'Лв.',
  FRANC = 'Fr.',
  KORUNA = 'Kč',
  KUNA = 'kn',
  FORINT = 'Ft',
  PESO = '$',
  LEU = 'L',
}

export enum RecurlyAddOnCode {
  RECURLY_V1_PLAN_ADD_ON_CODE = 'teamsseatv1',
  RECURLY_FLAT_PRICE_ADD_ON_CODE = 'teamsseatv2',
}

export interface BusinessPaymentPlan {
  currency: Currency;
  discount_percent?: number;
  formatted_monthly_price: string;
  formatted_price: string;
  formatted_savings_six_months?: string;
  monthly_price_without_savings?: string;
  formatted_savings?: string;
  period_number: number;
  period?: 'month' | 'six_months' | 'year';
  plan_id: string;
  price_without_savings: string;
  price: number;
  title: string;
  plan_num_seats?: number;
  custom_name?: string;
  is_bank_debit_enabled: boolean;
  is_trial_plan: boolean;
  is_trial_with_payment_info: boolean;
  recurly_add_on_code?: RecurlyAddOnCode;
}

export interface PlanCoupon {
  discount_percent?: number;
  amount_in_cents?: number;
  description: string;
  code: string;
  coupon_id: string;
  duration: string;
  name: string;
}

export enum CouponStatus {
  LOADING = 'LOADING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  RESET = 'RESET',
}

export enum PaymentMethod {
  CREDITCARD = 'credit_card',
  PAYPAL = 'paypal',
  GOOGLE_PAY = 'google_pay',
  APPLE_PAY = 'apple_pay',
  PAYTM = 'paytm',
  AFTERPAY_CLEARPAY = 'afterpay_clearpay',
  UPI = 'upi',
  EMI = 'emi',
  NET_BANKING = 'net_banking',
}

export enum CardBrand {
  VISA = 'Visa',
  AMEX = 'American Express',
  UNION = 'Union',
  MAESTRO = 'Mestro',
  DISCOVER = 'Discover',
}

export interface PaypalToken {
  id: string;
}

export type DispatchPaypalErrors = (errors: string, fullMsg: string) => void;

export enum PaymentMethodBusiness {
  CREDIT_CARD = 'credit_card',
  PAYPAL = 'paypal',
}

export enum BusinessTransactionType {
  CONVERT_TRIAL = 'convertTrial',
  CREATE_TRIAL = 'createTrial',
  RENEW = 'renew',
  UPSELL = 'upsell',
}

export enum BusinessRecurlyPlanCode {
  RECURLY_V1_PLAN_CODE = 'businessteamsv1',
  RECURLY_V2_PLAN_CODE = 'businessteamsv2',
}
