import { createSelector } from 'reselect';

import { SiteState } from '~/state/types';

export const selectAllModules = (state: SiteState) =>
  state.entities.modules.byId || state.entities.modules.bySlug || {};

export const selectModule = createSelector(
  selectAllModules,
  (_: SiteState, id: string) => id,
  (modules = {}, id) => {
    return modules[id];
  }
);

export const selectModuleBySlug = createSelector(
  selectAllModules,
  (_: SiteState, slug: string) => slug,
  (modules = {}, slug) => {
    return Object.values(modules).find((mod) => mod.slug === slug)!;
  }
);

export const selectModules = createSelector(
  selectAllModules,
  (_: SiteState, ids: string[]) => ids,
  (modules = {}, ids = []) => {
    return ids.map((id) => modules[id]).filter((mod) => mod);
  }
);

export const selectAllModuleReviewCards = (state: SiteState) =>
  state.entities.modules.reviewCards;

export const selectModuleReviewCards = (state: SiteState, moduleId: string) =>
  selectAllModuleReviewCards(state)?.[moduleId];

export const selectModuleHasReviewCards = createSelector(
  selectModule,
  selectModuleReviewCards,
  (module, reviewCards) => {
    if (module?.review_card_ids) return module.review_card_ids.length > 0;
    return reviewCards && reviewCards.length > 0;
  }
);
