const getCCDATA = () =>
  (typeof window !== 'undefined' && window.CCDATA) || ({} as any);

/**
 * @deprecated Use Redux instead!
 * @remarks
 * Ok, technically this isn't "deprecated": there are some real use cases for CCData,
 * mostly around storing _never-changing_ global API configurations and authentication keys.
 * But for the most part you should really be using Redux for shared data.
 * Talk to Web Platform if you want to use this. :)
 */
export const ccdata = {
  /**
   * @deprecated Use Redux instead!
   * @remarks
   * Ok, technically this isn't "deprecated": there are some real use cases for CCData,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  get<Key extends keyof CCDataGlobal>(
    key: Key,
    defaultValue?: CCDataGlobal[Key]
  ): CCDataGlobal[Key] {
    return getCCDATA()[key] || defaultValue;
  },

  /**
   * @deprecated Use Redux instead!
   * @remarks
   * Ok, technically this isn't "deprecated": there are some real use cases for CCData,
   * mostly around storing _never-changing_ global API configurations and authentication keys.
   * But for the most part you should really be using Redux for shared data.
   * Talk to Web Platform if you want to use this. :)
   */
  has(key: string) {
    return key in getCCDATA();
  },
};
