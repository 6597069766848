import { EventChannel, eventChannel } from 'redux-saga';
import { call, cancelled, put, take } from 'redux-saga/effects';

import { pageVisibilityChange } from './actions';

export function createChangeChannel() {
  return eventChannel((emit) => {
    const handler = () => {
      emit(document.hidden);
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      document.removeEventListener('visibilitychange', handler);
    };
  });
}

export function* pageVisibilitySagas() {
  // If the page is initially hidden, go ahead and announce it.
  if (document.hidden) {
    yield put(
      pageVisibilityChange({
        hidden: true,
      })
    );
  }

  // Then listen for visibility chanages.
  const changeChan: EventChannel<null> = yield call(createChangeChannel);

  try {
    while (true) {
      const hidden: boolean = yield take(changeChan);
      yield put(
        pageVisibilityChange({
          hidden,
        })
      );
    }
  } finally {
    const isCancelled: true = yield cancelled();
    if (isCancelled) {
      changeChan.close();
    }
  }
}
