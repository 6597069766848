import { createSelector } from 'reselect';

import { isChromeOS } from '~/libs/device';

import {
  selectUserIsInMoneybackGuaranteeExperiment,
  selectUserIsInOldMoneybackGuaranteeExperiment,
} from './currentUser/selectors';
import { selectActiveFeatures } from './features/selectors';
import { selectIsInMoneybackGuaranteePeriod } from './payment/selectors';

export const selectIsServiceWorkerEnabled = createSelector(
  selectActiveFeatures,
  (activeFeatures) => {
    return (
      activeFeatures.includes('service_workers_enabled') ||
      (activeFeatures.includes('service_workers_always_for_chromeos') &&
        isChromeOS())
    );
  }
);

export const selectIsMoneybackGuaranteeUser = createSelector(
  selectUserIsInMoneybackGuaranteeExperiment,
  selectUserIsInOldMoneybackGuaranteeExperiment,
  selectIsInMoneybackGuaranteePeriod,
  (variant, oldVariant, period) => (variant || oldVariant) && period
);
