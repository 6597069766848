/* eslint-disable you-dont-need-lodash-underscore/get */
import { differenceInSeconds } from 'date-fns';
import { get } from 'lodash';
import { createSelector, Selector } from 'reselect';

import { SiteState } from '../types';
import {
  AreaConfig,
  AreaShape,
  EntitiesShape,
  NotificationAreaId,
  NotificationEvent,
} from './types';

const selectPolling = (state: SiteState): boolean =>
  state.notifications?.polling;
const selectAreas = (state: SiteState): AreaShape => state.notifications?.areas;
const selectEntities = (state: SiteState): EntitiesShape =>
  state.notifications?.entities;

const byDateDesc = (notifA: NotificationEvent, notifB: NotificationEvent) => {
  return new Date(notifB.time).getTime() - new Date(notifA.time).getTime();
};

export const selectShouldPollNotifications = createSelector(
  selectPolling,
  selectAreas,
  (polling, areas = {}) => {
    return !polling && Object.keys(areas).length > 0;
  }
);

export const selectShouldStopPollNotifications = createSelector(
  selectPolling,
  selectAreas,
  (polling, areas) => {
    return polling && Object.keys(areas).length === 0;
  }
);

export const selectNotifications = createSelector(
  selectEntities,
  (notificationEntities = {}): NotificationEvent[] => {
    return Object.keys(notificationEntities)
      .map((id) => notificationEntities[id])
      .sort(byDateDesc);
  }
);

export const selectNotificationPriority = createSelector(
  selectAreas,
  (registeredAreas = {}): Record<string, NotificationAreaId> => {
    return Object.keys(registeredAreas).reduce<
      Record<string, NotificationAreaId>
    >((carry, area: NotificationAreaId) => {
      registeredAreas[area]?.types?.forEach((type) => (carry[type] = area));
      return carry;
    }, {});
  }
);

export const createSelectAreaConfig = (area: NotificationAreaId) => (
  state: SiteState
): AreaConfig | undefined => get(state, ['notifications', 'areas', area]);

export const createSelectNotificationsByArea = (area: NotificationAreaId) => {
  return createSelector(
    selectNotifications,
    selectNotificationPriority,
    createSelectAreaConfig(area),
    (notifications, priority, areaConfig) => {
      if (!areaConfig) {
        return [];
      }

      const {
        types = [],
        showRead = false,
        limit = notifications.length,
        timeframe,
      } = areaConfig;

      return notifications
        .filter(({ type, status, time, campaign_name }) => {
          const targetArea = priority[type] || priority[campaign_name];
          const shouldBeSeen = showRead || status === 1;
          const isValidArea =
            (!targetArea && types.length === 0) ||
            targetArea === area ||
            area === 'bell';

          const isInTimeframe = timeframe
            ? differenceInSeconds(new Date(), new Date(time)) < timeframe
            : true;

          return shouldBeSeen && isValidArea && isInTimeframe;
        })
        .slice(0, limit);
    }
  );
};

export const createSelectUnreadNotificationsCount = (
  selector: Selector<SiteState, NotificationEvent[]>
) =>
  createSelector(selector, (notifications: NotificationEvent[]): number =>
    notifications.reduce((count, { status }) => {
      if (status === 1) count += 1;
      return count;
    }, 0)
  );
